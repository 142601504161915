import React from 'react';
import { Helmet } from 'react-helmet';

// Font-Awesome Icons
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../images/logo.png';

/* Component ==================================================================== */
const BookingEngineLaunch = () => {
  return (
    <div className="index screen-container site-wrapper site-wrapper-absolute">
      <Helmet>
        <title>Booking Engine</title>
        <meta
          name="description"
          content="Renzo uses bookings.renzo.in as a part of service to facilitate bookings."
        />
      </Helmet>
      <div className="site-wrapper-inner">
        <div className="cover-container container row mx-auto">
          <div className="col-lg-8 col-md-12 col-sm-12 mx-auto">
            <h1>
              <FontAwesomeIcon className={'green-cl'} icon={faBed} size={'sm'}/> Renzo Booking Engine
            </h1>
            <p className={'lead'}>only bookings accepted here :)</p>
            <p>Renzo uses booking engine as a part of service to facilitate online bookings for our partners.</p>
            <p>Booking engines are created by our partners to list their inventory online, for any other information go
              to <a href="https://renzo.in">renzo.in</a>.</p>
            <div>
              <div className="text-md-end text-center brand">
                <img width={120} alt={'Renzo'} src={logo}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* Export ==================================================================== */
export default BookingEngineLaunch;
