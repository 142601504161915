import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faNoteSticky } from '@fortawesome/free-solid-svg-icons';

/* ComponentConnect ==================================================================== */
const FAQList = ({ FAQList, title }) => {
  return (
    <div className="faq-list">
      {title &&
      <h6 className={'mb-4'}>
        <FontAwesomeIcon icon={faNoteSticky} className={'gray-cl me-1'}/> {title}
      </h6>
      }
      {!_.isEmpty(FAQList) &&
      <Accordion>
        {FAQList.map((data, i) => (
          <Accordion.Item eventKey={i} key={i}>
            <Accordion.Header>
              <FontAwesomeIcon icon={faDotCircle} size={'xs'} className={'gray-cl me-2'}/>
              <strong>{data.title}</strong>
            </Accordion.Header>
            <Accordion.Body>{data.description}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      }
    </div>
  );
};

FAQList.propTypes = {
  FAQList: PropTypes.array,
  title: PropTypes.string
};

FAQList.defaultProps = {
  title: 'Frequently Asked Questions'
};

/* Export ==================================================================== */
export default FAQList;
