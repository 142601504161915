import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// Consts & Libs
import { Strings } from '../../constants';
// Components
import PageHeader from '../../components/booking-engine/PageHeader';
import AmenitiesList from '../../components/booking-engine/Amenities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServicestack } from '@fortawesome/free-brands-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
const PropertyAmenities = (props) => {
  const { property } = props;
  const { booking_engine_content } = property;

  if (_.isEmpty(property) || _.isEmpty(booking_engine_content)) return <></>;

  return (
    <div>
      <Helmet>
        <title>Amenities | {property.name}</title>
        <meta name="description" content={`Image gallery of ${property.name}. ${booking_engine_content.bengine_short_description}`}/>
      </Helmet>

      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <PageHeader
              back={false}
              title={'Amenities'}
              description={`Service and facilities at ${property.name}`}
              icon={<FontAwesomeIcon icon={faServicestack} className={'me-2 grey-cl'}/>}
            />
          </div>
        </div>
        {!_.isEmpty(booking_engine_content.bengine_amenities_list)
          ? <AmenitiesList amenitiesList={booking_engine_content.bengine_amenities_list}/>
          : <div className={'col-12 text-center'}>
            <div className={'w-50 mx-auto mb-5'}>
              <img alt={'Amenity List'} className="w-100" src={require('../../images/resource/cycling-bg.png')}/>
            </div>
            <p>{Strings.amenitiesList}</p>
          </div>
        }
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(PropertyAmenities);

/* Export ==================================================================== */
const PropertyAmenitiesPage = {
  element: <Element/>
};

export default PropertyAmenitiesPage;
