import _ from 'lodash';
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// Constants & Libs
import WebUtil from '../../lib/webUtils';
import { getPropertySlug } from '../../lib/http';
// Components
import Loading from '../../components/general/Loading';
import Property404 from '../property/components/Property404';
import FAQList from '../../components/booking-engine/FAQList';
import RoomTypePackageListing from './components/RoomTypePackageListing';
import SupportInformation from '../../components/booking-engine/supportInformation';
import AvailabilityLookupBox from '../../components/booking-engine/AvailabilityLookupBox';

import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBaby,
  faBed,
  faBoxOpen,
  faChild,
  faDoorOpen,
  faRulerCombined,
  faSmoking,
  faSmokingBan,
  faUser
} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as RoomActions from '../../redux/room/actions';
import * as GeneralActions from '../../redux/general/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.room.roomType
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomActions.setRoomType,
  setCaptcha: GeneralActions.setCaptcha
};

/* SSR Initiation Loading ======================================================= */
const loadData = (store, httpContext, { roomTypeID }) => {
  return store.dispatch(RoomActions.setRoomType(roomTypeID, getPropertySlug(httpContext)));
};

/* Component ==================================================================== */
const RoomTypeDetails = (props) => {
  const { property, roomType, setRoomType, setCaptcha } = props;
  const [loading, setLoading] = useState(roomType === null && !WebUtil.isSSR());
  const { roomTypeID } = useParams();
  const navigate = useNavigate();

  const doLookUp = (checkin, checkout, captcha) => {
    const location = `/room/availability/${Moment(checkin).format('YYYY-MM-DD')}/to/${Moment(checkout).format('YYYY-MM-DD')}/`;
    setCaptcha(captcha)
      .then(() => navigate(location));
  };

  useEffect(() => {
    setLoading(true);
    setRoomType(roomTypeID, property.property_slug)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomTypeID]);

  if (loading) return <Loading/>;
  if (_.isEmpty(roomType) && (WebUtil.isSSR() || !loading)) return <Property404 property={property}/>;

  return (
    <div>
      <Helmet>
        <title>{`${roomType.name} | ${property.name}`}</title>
        <meta
          name="description"
          content={roomType.short_description || `${roomType.name} | ${property.name}`}
        />
      </Helmet>
      {roomType.room_type_cover_image && roomType.room_type_cover_image.cover_2x &&
      <div
        className={'room-type-header'}
        style={roomType.room_type_cover_image && roomType.room_type_cover_image.cover_2x && {
          backgroundImage: `url(${roomType.room_type_cover_image.cover_2x})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className={'room-type-header-inner'}/>
      </div>
      }
      <div className={'container pt-4'}>
        <nav aria-label="breadcrumb mb-3">
          <ol className="breadcrumb small bg-light p-2">
            <li className="breadcrumb-item" aria-current="page"><Link to={'/'}>Home</Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to={'/room/'}>Room(s)</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{roomType.name}</li>
          </ol>
        </nav>

        <h1 className={'mb-4'}>{roomType.name}</h1>
        <div className={'row'}>
          <div className={'col-lg-8 sm-12 mb-5'}>

            {!_.isEmpty(roomType.slider_images) &&
            <Carousel className={'mb-4'}>
              {roomType.slider_images.map((data, i) =>
                <Carousel.Item key={i}>
                  <img className="d-block w-100" alt={'Slider'} src={data.image.slider_2x}/>
                </Carousel.Item>
              )}
            </Carousel>
            }
            <ul className="list-inline inline-list-spacing small mb-3">
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faUser} size={'sm'} className={'me-2 blue-cl'}/>
                {roomType.base_occupancy} {parseInt(roomType.child_occupancy) === 1 ? 'Guest' : 'Guests'}

              </li>
              {roomType.child_occupancy !== 0 &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faChild} size={'sm'} className={'me-2 blue-cl'}/>
                {roomType.child_occupancy} {parseInt(roomType.child_occupancy) === 1 ? 'Child' : 'Children'}
              </li>
              }
              {roomType.infant_occupancy !== 0 &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faBaby} className={'me-2 blue-cl'}/>
                {roomType.infant_occupancy} {parseInt(roomType.infant_occupancy) === 1 ? 'Infant' : 'Infants'}
              </li>
              }

              {roomType.room_dimension &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faRulerCombined} className={'me-2 blue-cl'}/>
                {roomType.room_dimension}
              </li>
              }

              {roomType.smoking_type_display &&
              <li className="list-inline-item">
                <FontAwesomeIcon
                  icon={roomType.smoking_type_display.includes('Non') ? faSmokingBan : faSmoking}
                  className={'me-2 blue-cl'}/> {roomType.smoking_type_display}
              </li>
              }

              {roomType.bed_type_display &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faBed} className={'me-2 blue-cl'}/> {roomType.bed_type_display}
              </li>
              }

              {roomType.view_type_display &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faDoorOpen} className={'me-2 blue-cl'}/> {roomType.view_type_display}
              </li>
              }
            </ul>

            <hr/>

            {roomType.short_description &&
            <p className={'display-linebreak font-weight-light mb-4'}>{roomType.short_description}</p>
            }

            {!_.isEmpty(roomType.packages) &&
            <div className={'mb-4'}>
              <h6 className={'mb-4'}><FontAwesomeIcon icon={faBoxOpen} className={'gray-cl mr-1'}/> Packages</h6>
              <div className={'mb-4'}>
                <AvailabilityLookupBox header={false} singleLine={true} action={doLookUp}/>
              </div>
              <div className="list-group list-group-sm mb-3">
                {roomType.packages.map((data, i) => (
                  <RoomTypePackageListing data={data} key={i}/>
                ))}
              </div>
            </div>
            }

            {(property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_faq)) &&
            <div>
              <hr className={'mb-4'}/>
              <div className={'mb-4'}>
                <FAQList FAQList={property.booking_engine_content.bengine_faq}/>
              </div>
            </div>
            }
          </div>
          <div className={'col-md-4 col-sm-12 mb-5'}>
            <div className={'mb-3'}>
              <AvailabilityLookupBox action={doLookUp}/>
            </div>
            <div className={'mb-3'}>
              <SupportInformation property={property}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(RoomTypeDetails);

/* Export ==================================================================== */
const RoomTypeDetailsPage = {
  loadData,
  element: <Element/>
};

export default RoomTypeDetailsPage;
