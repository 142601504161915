import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import { Constants } from '../../../constants';
import WEBUtil from '../../../lib/webUtils';
// Components
import OrderItemDetails from '../model/OrderItemDetails';

import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faEye, faSpinner, faSync } from '@fortawesome/free-solid-svg-icons';
/* ComponentConnect ==================================================================== */
const OrderListView = ({ order, refreshing, refresh }) => {
  const [orderItem, setOrderItem] = useState();
  const [showRefresh, setShowRefresh] = useState(true);
  const [showOrderItemDetails, setShowOrderItemDetails] = useState(false);
  const [showTax, setShowTax] = useState();
  let attempt = 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkPaymentStatus(), []);

  const checkPaymentStatus = () => {
    if (!_.isEmpty(order.payments) && attempt < 3) {
      order.payments.forEach((data) => {

        if ([Constants.FETCH_STATUS.INITIATED, Constants.FETCH_STATUS.PROCESSING].includes(data.pgateway_status)) {
          setTimeout(() => {
            attempt = (attempt + 1);
            setShowRefresh(true);
            refresh();
            checkPaymentStatus();
          }, 5000);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className={'list-group mb-3'}>
        <div className={'list-group-item'}>
          <ul className={'list-inline list-inline-formatted'}>
            <li className={'list-inline-item'}>
              <p className={'fs-6 text-muted mb-0'}>Order Reference</p>
              <p>{order.ref_no}</p>
            </li>
            <li className={'list-inline-item'}>
              <p className={'fs-6 text-muted mb-0'}>Order Status</p>
              <span className={`badge ${WEBUtil.paymentStatusColor(order.order_status)}`}>
                {order.order_status_display}
              </span>
            </li>

            {parseFloat(order.total_pending) > 0 &&
            <li className={'list-inline-item'}>
              <p className={'fs-6 text-muted mb-0'}>Payment Pending</p>
              <p><strong>{order.currency} {order.total_pending}</strong></p>
            </li>
            }
            {parseFloat(order.total_refunded) > 0 &&
            <li className={'list-inline-item'}>
              <p className={'fs-6 text-muted mb-0'}>Payment Refunded</p>
              <p><strong>{order.currency} {order.total_refunded}</strong></p>
            </li>
            }
            <li className={'list-inline-item'}>
              <p className={'fs-6 text-muted mb-0'}>Total</p>
              <p><strong>{order.currency} {order.total}</strong></p>
            </li>
          </ul>
        </div>
      </div>
      <div>
        {!_.isEmpty(order.order_items) ?
          <table className="table border">
            <tbody>
              {order.order_items.map((data, i) => {
                if (data.status) {
                  return (
                    <tr key={i}>
                      <td className={'w-10'}>
                        <p className={'mb-1 small text-end'}>
                          {data.quantity} <strong>x</strong>
                          <br/> {data.quantity_type_display}
                        </p>
                      </td>
                      <td className={'w-75'}>
                        <p className={'mb-1'}><strong>{data.description}</strong></p>
                        {data.additional_description &&
                          <p className={'small mb-1 text-muted'}>{data.additional_description}</p>
                        }
                        <p className={'mb-1 small'}>
                          {(parseInt(data.sub_quantity) > 1 || parseInt(data.sub_quantity_type) !== 1) &&
                        <React.Fragment>
                          {data.sub_quantity} {data.sub_quantity_type_display} |
                        </React.Fragment>
                          }
                        Price: {order.currency} {data.price} | Tax: {order.currency} {data.tax}
                          {parseFloat(data.discount) > 0 &&
                        <React.Fragment>
                          | Discount: {order.currency} {data.discount} ({data.discount_percent}%)
                        </React.Fragment>
                          }
                        </p>
                        <p className={'mb-0 small'}>
                          <span className="a" onClick={() => {setShowOrderItemDetails(true); setOrderItem(data);}}>
                            <FontAwesomeIcon icon={faEye} size={'xs'}/> Details
                          </span>
                        </p>
                      </td>
                      <td className="w-25 text-end">
                        <p className={'mb-0'}><strong>{order.currency} {data.total}</strong></p>
                        {data.complimentary && <span className={'badge badge-info'}>Comp </span>}
                      </td>
                    </tr>
                  );
                }
                return <React.Fragment/>;
              })
              }
              <tr>
                <td className="text-start" colSpan={2}>Sub Total</td>
                <td className="text-end">{order.currency} {order.sub_total}</td>
              </tr>
              {parseFloat(order.complimentary_total) > 0 &&
            <tr>
              <td className="text-start" colSpan={2}>Complimentary</td>
              <td className="text-end">{order.currency} {order.complimentary_total}</td>
            </tr>
              }
              <tr>
                <td className="text-start" colSpan={2}>
                Tax
                  {(!_.isEmpty(order.tax_expanded) && !_.isEmpty(order.tax_expanded.tax_data)) &&
                <React.Fragment>
                  <p className={'a mb-0 small text-muted'} onClick={() => setShowTax(showTax === 1 ? 0 : 1)}>
                    ({showTax === 1 ? 'Hide' : 'Show'} Tax Information)
                  </p>
                  <Accordion activeKey={showTax}>
                    <Accordion.Collapse eventKey={1}>
                      <table className={'table table-sm text-secondary format-table border mt-2'}>
                        <thead>
                          <tr>
                            <th className={'fs-6 text-dark w-50'}>Tax Name</th>
                            <th className={'fs-6 text-dark'}>Pre Tax</th>
                            <th className={'text-dark fs-6 text-end'}>Tax Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.tax_expanded.tax_data.map((data, i) => (
                            <tr key={i}>
                              <td className={'fs-6'}>{data.name}</td>
                              <td className={'fs-6'}>
                                {data.pre_total ? `${order.currency} ${data.pre_total}` : '...'}
                              </td>
                              <td className={'fs-6 text-end'}>{`${order.currency} ${data.value}`}</td>
                            </tr>
                          ))}
                          <tr>
                            <td/>
                            <td/>
                            <td className={'fs-6 text-end'}>{`${order.currency} ${order.tax_total}`}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Accordion.Collapse>
                  </Accordion>
                </React.Fragment>
                  }
                </td>
                <td className="text-end">{order.currency} {order.tax_total}</td>
              </tr>
              {(parseFloat(order.order_discount) > 0) &&
            <tr>
              <td className="text-start" colSpan={2}>Discount</td>
              <td className="text-end">{order.currency} {order.order_discount}</td>
            </tr>
              }
              {parseFloat(order.total_rounding) !== 0 &&
            <tr>
              <td colSpan={2} className="text-start">
                Rounding
              </td>
              <td className="text-end">{order.currency} {order.total_rounding}</td>
            </tr>
              }
              <tr>
                <td className="text-start" colSpan={2}><strong>Total</strong></td>
                <td className="text-end">
                  <strong>{order.currency} {order.total}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          :
          <div className="text-center border rounded p-4">
            <h5><FontAwesomeIcon icon={faExclamation} className={'me-2'}/> No items</h5>
            <p className={'text-muted fs-6 mb-0'}>No items in this order</p>
          </div>
        }
      </div>
      <div>
        {!_.isEmpty(order.payments) ?
          <div>
            <ul className={'list-group'}>
              {order.payments.map((payment, i) => (
                <li className={'list-group-item'} key={i}>
                  <ul className={'list-inline list-inline-formatted'}>
                    <li className={'list-inline-item'}>
                      <p className={'fs-6 text-muted mb-0'}>Payment Reference</p>
                      <p>{payment.ref_no}</p>
                    </li>
                    {payment.payment_id &&
                    <li className={'list-inline-item'}>
                      <p className={'fs-6 text-muted mb-0'}>Payment ID</p>
                      <p>{payment.payment_id}</p>
                    </li>
                    }
                    <li className={'list-inline-item'}>
                      <p className={'fs-6 text-muted mb-0'}>Payment Status</p>
                      <span className={'badge ' + (WEBUtil.paymentStatusColor(payment.payment_status))}>
                        {payment.payment_status_display}
                      </span>
                    </li>
                    <li className={'list-inline-item'}>
                      <p className={'fs-6 text-muted mb-0'}>Total</p>
                      <p><strong>{payment.currency} {payment.total}</strong></p>
                    </li>
                    {(payment.pgateway_status !== Constants.FETCH_STATUS.NOT_REQUIRED) &&
                    <li className={'list-inline-item'}>
                      <p className={'fs-6 text-muted mb-0'}>Payment Link</p>
                      {payment.payment_link ?
                        <a className={'btn btn-success btn-block btn-sm mt-1 mb-0'} href={payment.payment_link}>
                          {payment.payment_status === 1 ? 'View Payment Receipt' : 'Make Payment'}
                        </a>
                        :
                        <div>
                          {!(payment.pgateway_status === Constants.FETCH_STATUS.INITIATED || payment.pgateway_status === Constants.FETCH_STATUS.PROCESSING) ?
                            <span className={'fs-6 text-muted'}>
                              <FontAwesomeIcon pulse icon={faSpinner} className={'me-2'}/>
                                generating...
                            </span>
                            :
                            <span className={`badge ${WEBUtil.fetchStatusColor(payment.pgateway_status)}`}>
                              {payment.pgateway_status_display}
                            </span>
                          }
                          {showRefresh &&
                          <button
                            onClick={() => refreshing ? null : refresh()}
                            className={'btn btn-outline-success btn-sm ms-5'}
                            disabled={payment.pgateway_status === Constants.FETCH_STATUS.FAILED}
                          >
                            <FontAwesomeIcon pulse={refreshing} icon={faSync} size={'sm'}/> Refresh
                          </button>
                          }
                        </div>
                      }
                    </li>
                    }
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          :
          <div className="text-center border rounded p-4">
            <h5><FontAwesomeIcon icon={faExclamation} className={'me-2'}/> No Payments</h5>
            <p className={'text-muted fs-6 mb-0'}>No payments in this order</p>
          </div>
        }
      </div>
      <OrderItemDetails
        order={order}
        orderItem={orderItem}
        show={showOrderItemDetails}
        onHide={()=>setShowOrderItemDetails(false)}
      />
    </React.Fragment>
  );
};

OrderListView.propTypes = {
  order: PropTypes.object.isRequired,
  refresh: PropTypes.func,
  refreshing: PropTypes.bool
};

/* Export ==================================================================== */
export default OrderListView;
