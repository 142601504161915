/**
 * Events Reducer
 *
 */

import _ from 'lodash';

// Set initial state
const initialState = {
  event: null
};

const reducer = (state = initialState, action) => {
  const actionData = (!_.isEmpty(action.data)) ? action.data : {};
  switch (action.type) {
  case 'SET_EVENT':
    return {
      ...state,
      event: actionData
    };
  case 'SET_TICKET_BOOKING':
    return {
      ...state,
      ticketBooking: actionData
    };
  default:
    return state;
  }
};

export default reducer;
