import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// Constants & Libs
import AppUtil from '../../../lib/util';
import WEBUtil from '../../../lib/webUtils';
import { Constants } from '../../../constants';

/* ComponentConnect ==================================================================== */
const EventDetailsBox = ({ event, header }) => {
  const { property } = useSelector(state => state.property);

  return (
    <div>
      {header &&
      <React.Fragment>
        <h5 className={'mb-1'}>Schedules</h5>
        <p className={'small text-muted'}>Timing and Other important information's about the event.</p>
      </React.Fragment>
      }

      <div className={'border rounded mb-5 '}>
        <table className={'table small mb-0'}>
          <tbody>
            <tr>
              <td width={'40%'}><p className={'mb-0'}><strong>Date</strong></p></td>
              <td><p className={'mb-0'}>
                {AppUtil.formatDateTime(event.event_start_date, 'date')}
                {event.event_type === Constants.EVENT_TYPE.RECURRING && `- ${AppUtil.formatDateTime(event.event_end_date, 'date')}`}
              </p></td>
            </tr>

            {/* Not rendering in Sever to avoid time zone issue */}
            <tr>
              <td width={'40%'}><p className={'mb-0'}><strong>Schedule</strong></p></td>
              <td>
                <p className={'mb-0'}>
                  <>
                    {AppUtil.formatDateTime(event.event_schedule_start_time, 'time', null, property.property_timezone)} to {parseInt(event.event_schedule_length) > 1 && ` + ${event.event_schedule_length} day(s)`} {AppUtil.formatDateTime(event.event_schedule_end_time, 'time', null, property.property_timezone)}
                  </>
                </p>
              </td>
            </tr>

            <tr>
              <td><p className={'mb-0'}><strong>Event Type</strong></p></td>
              <td><p className={'mb-0'}>{event.event_type_display}</p></td>
            </tr>

            {event.event_type === Constants.EVENT_TYPE.RECURRING &&
          <React.Fragment>
            <tr>
              <td><p className={'mb-0'}><strong>Recurring Type</strong></p></td>
              <td><p className={'mb-0'}>{event.event_recurring_type_display}</p></td>
            </tr>
            <tr>
              <td><p className={'mb-0'}><strong>Active day(s)</strong></p></td>
              <td><p className={'mb-0'}>{AppUtil.formatWeekDays(event.event_recurring_week_days)}</p></td>
            </tr>
          </React.Fragment>
            }

            {event.event_booking_end_duration_humanized &&
          <tr>
            <td><p className={'mb-0'}><strong>Ticket End Time</strong></p></td>
            <td><p className={'mb-0'}>{AppUtil.formatDuration(event.event_booking_end_duration_humanized)} Before event
              start.</p></td>
          </tr>
            }
            <tr>
              <td><p className={'mb-0'}><strong>Event Status</strong></p></td>
              <td>
                <span className={'badge ' + (WEBUtil.bookingStatusColor(event.event_status))}>
                  {event.event_status_display}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

EventDetailsBox.propTypes = {
  event: PropTypes.object,
  header: PropTypes.bool
};

EventDetailsBox.defaultProps = {
  header: true
};

/* Export ==================================================================== */
export default EventDetailsBox;
