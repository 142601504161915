/**
 * General reducer
 */
import _ from 'lodash';

// Set initial state
const initialState = {
  SSRData: {},
  captcha: {},
};

const reducer = (state = initialState, action) => {
  const actionData = (!_.isEmpty(action.data)) ? action.data : {};
  switch (action.type) {
  case 'SET_SSR_DATA':
    return {
      ...state,
      SSRData: actionData
    };
  case 'SET_CAPTCHA':
    return {
      ...state,
      captcha: actionData
    };
  default:
    return state;
  }
};

export default reducer;
