import _ from 'lodash';
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// Const & Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import RoomBooking from './models/RoomBooking';
import Alert from '../../components/general/Alert';
import RoomAvailabilityListing from './components/RoomAvailabilityListing';
import SupportInformation from '../../components/booking-engine/supportInformation';
import AvailabilityLookupBox from '../../components/booking-engine/AvailabilityLookupBox';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons';
// Actions
import * as GeneralActions from '../../redux/general/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  captcha: state.general.captcha
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setCaptcha: GeneralActions.setCaptcha,
  unSetCaptcha: GeneralActions.unSetCaptcha
};

/* Component ==================================================================== */
const RoomAvailability = (props) => {
  const { property, captcha, unSetCaptcha } = props;
  const { booking_engine_content } = property;

  const { checkin, checkout } = useParams();

  const [statusMsg, setStatusMsg] = useState({});
  const [lookupResponse, setLookupResponse] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [loadingInner, setLoadingInner] = useState(false);
  const [showRoomBooking, setShowRoomBooking] = useState(false);
  const [currentCheckin, setCurrentCheckin] = useState(checkin ? Moment(checkin) : Moment().startOf('day'));
  const [currentCheckout, setCurrentCheckout] = useState(checkout ? Moment(checkout) : Moment().add(1, 'days'));

  const totalRooms = cartItems.reduce((total, product) => total + product.quantity, 0);

  const navigate = useNavigate()

  useEffect(() => {
    if (!_.isEmpty(captcha) && checkin && checkout) {
      lookup(currentCheckin.format('YYYY-MM-DD'), currentCheckout.format('YYYY-MM-DD'), captcha);
      // Unset Captcha
      unSetCaptcha();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lookup = (checkin, checkout, captcha) => {
    if (checkin && checkout && captcha) {
      setLoadingInner(true);
      setStatusMsg({ status: 'One moment...' });

      AppAPI.bengineapi.post(`${property.property_slug}/booking/lookup/`, {
        checkin: Moment(checkin).format('YYYY-MM-DD'),
        checkout: Moment(checkout).format('YYYY-MM-DD'),
        recaptcha: captcha
      })
        .then(res => {
          setStatusMsg({
            success: `Availability for ${AppUtil.formatDateTime(res.checkin, 'datef')} to ${AppUtil.formatDateTime(res.checkout, 'datef')}`
          });
          setCartItems([]);
          setLookupResponse(res);
          setLoadingInner(false);
          setCurrentCheckin(Moment(checkin));
          setCurrentCheckout(Moment(checkout));
        })
        .catch(err => {
          setStatusMsg({ error: AppAPI.handleError(err) });
          setLoadingInner(false);
          setLookupResponse({});
        });
    }
  };

  const addToCart = (roomTypePackage) => {
    const { results } = lookupResponse;
    const tempCart = [...cartItems];
    if (results && results.find(item => (item.room_type === roomTypePackage.room_type))) {
      const {
        availability, max_quantity, occupancy_data, name
      } = results[results.findIndex(item => (item.room_type === roomTypePackage.room_type))];

      // For quantity filter all items in cart with room type
      let roomTypeQuantity = tempCart.filter(item => (item.room_type === roomTypePackage.room_type)).reduce((total, product) => total + product.quantity, 0);

      if (parseInt(availability.available_rooms) > roomTypeQuantity && max_quantity > roomTypeQuantity) {
        if (!tempCart.find(item => (item.room_type_package === roomTypePackage.room_type_package))) {
          // These data sets are used in booking preview and fixing no of guests refer usage in RoomBooking.js
          tempCart.push({
            room_type: roomTypePackage.room_type,
            room_type_package: roomTypePackage.room_type_package,
            package_data: roomTypePackage,
            room_type_data: {
              name: name,
              room_type: roomTypePackage.room_type
            },
            occupancy_data: occupancy_data,
            quantity: 1
          });
        } else if (parseInt(availability.available_rooms) > roomTypeQuantity && !availability.single_select) {
          tempCart[tempCart.findIndex(item => (item.room_type_package === roomTypePackage.room_type_package))].quantity++;
        }
        setCartItems(tempCart);
      }
    }
  };

  const removeFromCart = (roomTypePackage) => {
    const tempCart = [...cartItems];
    const item = tempCart[tempCart.findIndex(item => (item.room_type_package === roomTypePackage.room_type_package))];
    if (item && item.quantity > 1) {
      tempCart[tempCart.findIndex(item => (item.room_type_package === roomTypePackage.room_type_package))].quantity--;
      setCartItems(tempCart);
    } else if (item && item.quantity === 1) {
      setCartItems(tempCart.filter(item => (item.room_type_package !== roomTypePackage.room_type_package)));
    }
  };

  const room_types = lookupResponse.results || property.room_types;
  const bookButton = <button className={'btn btn-success'} onClick={() => setShowRoomBooking(true)}>
    Book {totalRooms} Room{totalRooms > 1 && 's'}
  </button>;

  return (
    <div>
      <Helmet>
        <title>{`Room(s): ${property.name}`}</title>
        <meta
          name="description"
          content={`Rooms in property ${property.name}. ${booking_engine_content.bengine_short_description}`}
        />
      </Helmet>

      <div className={'container pt-4'}>
        <nav aria-label="breadcrumb mb-3">
          <ol className="breadcrumb small bg-light p-2">
            <li className="breadcrumb-item" aria-current="page">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to={'/room/'}>Room</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Availability Lookup
            </li>
          </ol>
        </nav>

        <div className={'row'}>
          <div className={'col-md-8 sm-12 mb-5'}>
            <div className={'mb-3'}>
              <AvailabilityLookupBox
                header={false}
                singleLine={true}
                selectedCheckinDate={currentCheckin}
                selectedCheckoutDate={currentCheckout}
                action={lookup}
              />
            </div>
            <Alert statusMsg={statusMsg}/>

            {room_types.map((data, key) => (
              <RoomAvailabilityListing
                data={data} key={key} loading={loadingInner}
                addToCart={addToCart}
                removeFromCart={removeFromCart}
                cartItems={cartItems.filter(item => (item.room_type === data.room_type))}
              />
            ))}
          </div>
          <div className={'col-md-4 col-sm-12 mb-5'}>
            <div>
              <SupportInformation property={property}/>
            </div>
            <div className="sticky-top pt-5">
              {_.isEmpty(cartItems) ?
                <AvailabilityLookupBox
                  action={lookup}
                  selectedCheckinDate={currentCheckin}
                  selectedCheckoutDate={currentCheckout}
                />
                :
                <ul className={'list-group'}>
                  <li className={'list-group-item'}>
                    <p className={'mb-0 text-muted small'}>
                      <FontAwesomeIcon icon={faShoppingCart} className={'mr-2 blue-cl'}/> Itinerary
                    </p>
                  </li>
                  {cartItems.map((data, i) => (
                    <li className={'list-group-item d-inline-flex'} key={i}>
                      <p className={'mb-0'}>
                        <strong>{data.quantity}</strong>
                        <FontAwesomeIcon icon={faTimes} className={'mx-2'}/>
                      </p>
                      <p className={'mb-0'}>
                        {data.room_type_data.name}<br/>
                        <span className={'small text-muted'}>{data.package_data.name}</span>
                      </p>
                    </li>
                  ))}
                  <li className={'list-group-item'}>
                    <div className={'d-grid'}>{bookButton}</div>
                    <div className={'fixed-bottom w-100 white-bg p-3 border-top text-center d-md-none d-grid'}>
                      {bookButton}
                    </div>
                  </li>
                </ul>
              }
            </div>
          </div>
        </div>
      </div>
      <RoomBooking
        show={showRoomBooking}
        onHide={() => setShowRoomBooking(false)}
        cartData={cartItems}
        property={property}
        checkin={currentCheckin}
        checkout={currentCheckout}
        onSuccess={(data) => navigate(`/booking/r/${data.hash}`)}
      />
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(RoomAvailability);

/* Export ==================================================================== */
const RoomAvailabilityPage = {
  element: <Element/>
};

export default RoomAvailabilityPage;
