import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Constants & Libs
import { Constants } from '../../../constants';
// Components
import { Accordion, Modal } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* ComponentConnect ==================================================================== */
const ComponentModel = (props) => {

  const { order, orderItem, show } = props;
  const [showTax, setShowTax] = useState();

  if (!show) return <></>;

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="md"
        centered={true}
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Item Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <table className={'table small table border'}>
              <tbody>
                <tr>
                  <td className={'w-75 border-top-0'} colSpan={2}>
                    <p className={'mb-1'}><strong>{orderItem.description}</strong></p>
                    {orderItem.additional_description &&
                      <p className={'small text-muted mb-0'}>{orderItem.additional_description}</p>
                    }
                  </td>
                </tr>
                <tr>
                  <td className={'w-75'}>
                  Price
                  </td>
                  <td className={'text-end'}>
                    <strong>{order.currency} {orderItem.price}</strong>
                  </td>
                </tr>
                {orderItem.sub_quantity_type !== Constants.QUANTITY_TYPE.NOS &&
              <tr>
                <td className={'w-75'}>
                  {orderItem.sub_quantity} <small>{orderItem.sub_quantity_type_display}</small> Price
                </td>
                <td className={'text-end'}>
                  <strong>{order.currency} {orderItem.sub_quantity_sub_total}</strong>
                </td>
              </tr>
                }
                <tr>
                  <td className={'w-75'}>
                    {orderItem.sub_quantity} <small>{orderItem.sub_quantity_type_display}</small>
                    <strong>x</strong> {orderItem.quantity} <small>{orderItem.quantity_type_display}</small>
                  </td>
                  <td className={'text-end'}>
                    <strong>{order.currency} {orderItem.sub_total}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr/>
            <table className={'table small border'}>
              <tbody>
                <tr>
                  <td className={'w-75'}>Sub Total</td>
                  <td className={'text-end'}><strong>{order.currency} {orderItem.sub_total}</strong>
                  </td>
                </tr>
                {parseFloat(orderItem.discount) > 0 && orderItem.discount_before_tax && (
                  <tr>
                    <td className={'w-75'}>Discount</td>
                    <td className={'text-end'}><strong>- {order.currency} {orderItem.discount}</strong>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={'w-75'}>
                  Tax
                    {orderItem.tax_data && orderItem.tax_data.description && (
                      <p className={'small text-muted mb-0'}>{orderItem.tax_data.description}</p>
                    )}
                    {orderItem.tax_expanded && orderItem.tax_expanded.tax_data && orderItem.tax_expanded.tax_data.length > 0 &&
                  <React.Fragment>
                    <p
                      className={'a mb-0 small text-muted'}
                      onClick={() => setShowTax(showTax === 1 ? 0 : 1)}
                    >
                        ({showTax === 1 ? 'Hide' : 'Show'} Tax Information)
                    </p>
                    <Accordion activeKey={showTax}>
                      <Accordion.Collapse eventKey={1}>
                        <table className={'table table-sm text-secondary border mt-2'}>
                          <thead>
                            <tr>
                              <th className={'small text-dark'}>Tax Name</th>
                              <th className={'text-end text-dark small'}>Tax Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderItem.tax_expanded.tax_data.map((data, i) => (
                              <tr key={i}>
                                <td className={'small'}>{data.name}</td>
                                <td className={'text-end small'}>{order.currency} {data.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Collapse>
                    </Accordion>
                  </React.Fragment>
                    }
                  </td>
                  <td className={'text-end'}><strong>{order.currency} {orderItem.tax}</strong></td>
                </tr>
                {parseFloat(orderItem.discount) > 0 && !orderItem.discount_before_tax && (
                  <tr>
                    <td className={'w-75'}>Discount</td>
                    <td className={'text-end'}><strong>{order.currency} {orderItem.discount}</strong>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={'w-75'}><strong>Total</strong></td>
                  <td className={'text-end'}><strong>{order.currency} {orderItem.total}</strong></td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <div className={'row w-100'}>
            <div className={'col d-grid'}>
              <button className={'btn btn-lg btn-secondary'} onClick={props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

ComponentModel.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  order: PropTypes.object,
  orderItem: PropTypes.object
};

/* Export ==================================================================== */
export default ComponentModel;
