import AppAPI from '../../lib/api';


export const setProperty = (propertyData) => (dispatch) => new Promise((resolve, reject) => {
  return AppAPI.bengineapi.get(`${propertyData}/`)
    .then((res) => {
      dispatch({
        type: 'SET_PROPERTY',
        data: res
      });
      return resolve(res);
    })
    .catch(err => reject(err));
});

export const setPage = (pageData, propertyId) => (dispatch) => new Promise(async (resolve, reject) => {
  return AppAPI.bengineapi.get(`${propertyId}/page/${pageData}/`)
    .then((res) => {
      dispatch({
        type: 'SET_PAGE',
        data: res
      });
      return resolve(res);
    })
    .catch(err => {
      dispatch({
        type: 'SET_PAGE',
        data: {}
      });
      return reject(err);
    });
});
