import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
// Components
import ClickToView from '../general/ClickToView';
import SocialLinks from './components/socialLinks';
import FooterLinks from '../booking-engine/PolicyLinks';

/* Component ==================================================================== */
const Footer = () => {

  const property = useSelector(state => state.property.property);
  if (_.isEmpty(property)) return <></>;

  return (
    <div className="footer border-top">
      <div className="footer border-top">
        <div className={'container my-3'}>
          <div className={'row'}>
            {property.google_maps_url &&
            <div className={'col-sm-12 col-md-4'}>
              <iframe
                title={'Google Maps'}
                src={property.google_maps_url}
                className={'w-100'}
                height={200}
                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
              />
            </div>
            }
            <div className={'col-sm-12 col-md-4'}>
              <h5>{property.name}</h5>
              <p className={'text-muted small'}>{property.full_address}</p>
              {property.sales_email &&
              <p className={'text-muted small mb-0'}>
                E-mail : <ClickToView buttonClass={'btn-link'} content={property.sales_email}/>
              </p>
              }
              {property.sales_phone &&
              <p className={'text-muted small'}>
                Phone : <ClickToView buttonClass={'btn-link'} content={property.sales_phone}/>
              </p>
              }
              {property.booking_engine_content.bengine_social_links &&
              <SocialLinks socialLinks={property.booking_engine_content.bengine_social_links}/>
              }
            </div>

            <div className={'col-sm-12 col-md-4'}>
              {property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_footer_links) &&
              <div className={'footer-links'}>
                <FooterLinks Links={property.booking_engine_content.bengine_footer_links}/>
              </div>
              }
            </div>
          </div>
        </div>
        <div className={'border-top'}>
          <div className="px-3 pt-3">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 text-lg-start text-center">
                {property.name &&
                <p><span className="text-muted small">{property.name}</span></p>
                }
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                {!_.isEmpty(property.global_content) &&
                <div className={'policy-link'}>
                  <FooterLinks inLine={true} Links={property.global_content.policy_links}/>
                </div>
                }
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 text-lg-end text-center">
                <p className={'text-muted small'}>
                  Powered by <a href={'https://renzo.in'} rel="noopener noreferrer" target={'_blank'}>Renzo</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* Export ==================================================================== */
export default Footer;
