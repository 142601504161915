import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import { Strings } from '../../constants';
// Components
import ListView from '../../components/general/ListView';
import PageHeader from '../../components/booking-engine/PageHeader';

import { faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
const PropertyImageGallery = (props) => {
  const { property } = props;
  const { booking_engine_content } = property;

  if (_.isEmpty(property)) return <></>;

  const fetchData = (page, callback) => {
    AppAPI.bengineapi.get(`${property.property_slug}/gallery/?${page && `page=${page}`}`)
      .then((res) => {
        res.results.length > 0 ? callback(res.results, { nextParam: res.next ? AppUtil.getURLParam(res.next, 'page') : false }) : callback();
      })
      .catch((err) => {
        callback()
        AppAPI.handleError(err);
      });
  };

  const emptyView = () => {
    return (
      <div className={'col-12 text-center mb-5'}>
        <div className={'w-50 mx-auto mb-5'}>
          <img alt={'Empty Events'} className="w-100" src={require('../../images/resource/cycling-bg.png')}/>
        </div>
        <p>{Strings.galleryImageGallery}</p>
      </div>
    );
  };

  const renderRow = (data, key) => {
    return (
      <div className={'col-sm-12 col-md-6 mb-3'} key={key}>
        <img alt={data.description} className="d-block w-100" src={data.image.slider_2x}/>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Gallery | {property.name}</title>
        <meta name="description" content={`Image gallery of ${property.name}. ${booking_engine_content.bengine_short_description}`}/>
      </Helmet>

      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <PageHeader
              back={false}
              title={'Gallery'}
              icon={<FontAwesomeIcon icon={faImages} className={'me-2 grey-cl'}/>}
            />
            <div className={'row mb-5'}>
              <ListView
                rowView={renderRow}
                onFetch={fetchData}
                firstLoader={true}
                emptyView={emptyView}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(PropertyImageGallery);

/* Export ==================================================================== */
const PropertyImageGalleryPage = {
  element: <Element/>
};

export default PropertyImageGalleryPage;
