import React from 'react';
import { Outlet } from 'react-router-dom';
// Components
import EnvironmentWarning from './components/general/EnvironmentWarning';

import './theme/style.css';
import './theme/booking-engine.css';

/* Component ==================================================================== */
const App = () => {
  return (
    <div className={'root-container'}>
      <EnvironmentWarning/>
      <div className={'main-container'}>
        <Outlet/>
      </div>
    </div>
  );
};

/* Export ==================================================================== */
const AppComponent = {
  element: <App/>
};
export default AppComponent;
