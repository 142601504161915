/**
 * Social Links
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

/* Functions ==================================================================== */

const renderIcon = (title) => {
  let icon;
  switch (title) {
  case 'FB':
    icon = faFacebook;
    break;
  case 'TW':
    icon = faTwitter;
    break;
  case 'IG':
    icon = faInstagram;
    break;
  case 'YT':
    icon = faYoutube;
    break;
  default:
    icon = faStar;
  }
  return icon;
};

/* Component ==================================================================== */
const SocialLinks = (props) => {
  const { socialLinks } = props;

  if (_.isEmpty(socialLinks)) return <></>;

  return (
    <div className="social-links">
      <ul className="list-inline">
        <li className="list-inline-item small text-muted"> Follow us on :</li>
        {socialLinks.map((data, i) => (
          <li className="list-inline-item" key={i}>
            <a href={data.link} rel="noopener noreferrer" target={'_blank'}>
              <FontAwesomeIcon icon={renderIcon(data.title)} className={'mr-1'}/>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

SocialLinks.propTypes = {
  socialLinks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

/* Export Component ==================================================================== */
export default SocialLinks;
