import _ from 'lodash';
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// Const & Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import WebUtil from '../../lib/webUtils';
import { Constants } from '../../constants';
import { getPropertySlug } from '../../lib/http';
// Components
import Alert from '../../components/general/Alert';
import Loading from '../../components/general/Loading';
import EventDetailsBox from './components/EventDetailsBox';
import EventTicketBooking from './models/EventTicketBooking';
import Property404 from '../property/components/Property404';
import FAQList from '../../components/booking-engine/FAQList';
import EventTicketListing from './components/EventTicketListing';
import SupportInformation from '../../components/booking-engine/supportInformation';
import AvailabilityLookupBoxEvent from '../../components/booking-engine/AvailabilityLookupBoxEvent';

import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
// Actions
import * as EventActions from '../../redux/event/actions';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent
};

/* SSR Initiation Loading ======================================================= */
const loadData = (store, httpContext, { eventID }) => {
  return store.dispatch(EventActions.setEvent(eventID, getPropertySlug(httpContext)));
};

/* Component ==================================================================== */
const EventDetails = (props) => {
  const { property, setEvent, event } = props;
  const [ticket, setTicket] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [statusMsg, setStatusMsg] = useState({});
  const [loadingInner, setLoadingInner] = useState(false);
  const [lookupResponse, setLookupResponse] = useState([]);
  const [showTicketBooking, setShowTicketBooking] = useState(false);
  const [loading, setLoading] = useState(event === null && !WebUtil.isSSR());

  const { eventID } = useParams();

  const ticketLookup = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setEvent(eventID, property.property_slug)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID]);

  const lookup = (date, captcha) => {
    if (date && captcha) {
      if (ticketLookup) {
        setTimeout(() => {
          ticketLookup.current.scrollIntoView();
        }, 1e3 / 60);
      }
      setLoadingInner(true);
      setStatusMsg({ status: 'One moment...' });
      setCurrentDate(Moment(date));
      AppAPI.bengineapi.post(`${property.property_slug}/event/${eventID}/lookup/`, {
        date: Moment(date).format('YYYY-MM-DD'),
        recaptcha: captcha
      })
        .then(res => {
          setStatusMsg({ success: `Availability for ${AppUtil.formatDateTime(res.date, 'datef')}` });
          setCurrentDate(Moment(res.date));
          setLoadingInner(false);
          setLookupResponse(res);
        })
        .catch(err => {
          setStatusMsg({ error: AppAPI.handleError(err) });
          setLoadingInner(false);
          setLookupResponse({});
        });
    }
  };

  if (loading) return <Loading/>;
  if (_.isEmpty(event) && (WebUtil.isSSR() || !loading)) return <Property404 property={property}/>;

  const sideBar = (
    <div>
      <div className={'mb-3'}>
        <AvailabilityLookupBoxEvent
          event={event}
          action={lookup}
          selectedDate={currentDate}
          disabledSelection={!_.isEmpty(lookupResponse)}
          disabledDates={(date) => WebUtil.disabledDaysFunction(date, event.event_recurring_week_days, event.event_start_date, event.event_end_date)}
        />
      </div>
      <div className={'mb-3'}>
        <EventDetailsBox event={event}/>
      </div>
      <div>
        <SupportInformation event={event} property={property}/>
      </div>
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>{`${event.name} | ${property.name}`}</title>
        <meta
          name="description"
          content={event.slider_description || `${event.name} | ${property.name}`}
        />
      </Helmet>

      <div
        className={'event-header'}
        style={event.event_cover_image && event.event_cover_image.cover_2x && {
          backgroundImage: `url(${event.event_cover_image.cover_2x})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className={'event-header-inner w-100 d-flex '}>
          <div className={'text-light align-self-center flex-fill'}>
            <div className={'container'}>
              <h1>{event.name}</h1>
              <p>
                <FontAwesomeIcon icon={faMapMarker} className={'me-2'}/>
                {event.full_address ? event.full_address : property.full_address}
              </p>
              <p>
                {AppUtil.formatDateTime(event.event_start_date, 'date')}
                {event.event_type === Constants.EVENT_TYPE.RECURRING &&
                `- ${AppUtil.formatDateTime(event.event_end_date, 'date')}`
                } |
                <span className={'ms-2 badge ' + (WebUtil.bookingStatusColor(event.event_status))}>
                  {event.event_status_display}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={'container pt-4'}>
        <nav aria-label="breadcrumb mb-3">
          <ol className="breadcrumb small bg-light p-2">
            <li className="breadcrumb-item" aria-current="page"><Link to={'/'}>Home</Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to={'/event/'}>Event(s)</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{event.name}</li>
          </ol>
        </nav>

        <div className={'row'}>
          <div className={'col-lg-8 sm-12 mb-5'}>
            {event.slider_description &&
            <p className={'display-linebreak font-weight-light mb-5'}>{event.slider_description}</p>
            }

            {!_.isEmpty(event.slider_images) &&
            <Carousel className={'mb-4'}>
              {event.slider_images.map((data, i) =>
                <Carousel.Item key={i}>
                  <img className="d-block w-100" alt={'Slider'} src={data.image.slider_2x}/>
                </Carousel.Item>
              )}
            </Carousel>
            }

            <div className={'mb-3 d-block d-lg-none'}>{sideBar}</div>

            {event.description &&
            <p className={'display-linebreak font-weight-light'}>{event.description}</p>
            }


            <div className={'mb-3'} ref={ticketLookup} >
              <AvailabilityLookupBoxEvent
                event={event}
                action={lookup}
                selectedDate={currentDate}
                disabledSelection={!_.isEmpty(lookupResponse)}
                disabledDates={(date) => WebUtil.disabledDaysFunction(date, event.event_recurring_week_days, event.event_start_date, event.event_end_date)}
              />
            </div>

            <Alert statusMsg={statusMsg}/>

            <h6 className={'mb-3'}><FontAwesomeIcon icon={faTicketAlt} className={'gray-cl mr-1'}/> Tickets</h6>

            <EventTicketListing
              loading={loadingInner}
              tickets={lookupResponse.results || event.tickets}
              action={(data) => {
                setShowTicketBooking(true);
                setTicket(data);
              }}
            />

            {(event.google_maps_url) &&
            <div className={'mb-3'}>
              <p>How to reach us</p>
              <iframe
                title={'Google Maps'}
                src={event.google_maps_url}
                className={'w-100'}
                height={400}
                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
              />
            </div>
            }

            {!_.isEmpty(event.event_faq) &&
            <div>
              <hr className={'mb-4'}/>
              <div className={'mb-4'}>
                <FAQList FAQList={event.event_faq}/>
              </div>
            </div>
            }
          </div>
          <div className={'col-sm-12 col-lg-4 d-none d-lg-block'}>{sideBar}</div>
        </div>
      </div>
      <EventTicketBooking
        show={showTicketBooking}
        onHide={() => setShowTicketBooking(false)}
        event={event}
        property={property}
        date={currentDate}
        ticket={ticket}
        onSuccess={(data) => navigate(`/booking/e/${data.hash}`)}
      />
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(EventDetails);

/* Export ==================================================================== */
const EventDetailsPage = {
  loadData,
  element: <Element/>
};

export default EventDetailsPage;
