import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _ from 'lodash';
// Form
import * as Yup from 'yup';
import { Formik } from 'formik';
// Const & Libs
import AppAPI from '../../../lib/api';
import AppUtil from '../../../lib/util';
import { AppConfig, SelectList, Strings } from '../../../constants';
// Components
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '../../../components/general/Alert';
import FAQList from '../../../components/booking-engine/FAQList';
import PolicyLinks from '../../../components/booking-engine/PolicyLinks';
import DateFormatted from '../../../components/booking-engine/DateFormatted';

import { Modal } from 'react-bootstrap';
import { Checkbox, Input, Select } from 'react-formik-ui';
import {
  faArrowRight, faContactCard,
  faDoorOpen,
  faFileContract,
  faMapMarker,
  faMinus,
  faPlus,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* ComponentConnect ==================================================================== */
const RoomBooking = (props) => {

  const { cartData, property, show, checkin, checkout } = props;
  const [resultMsg, setResultMsg] = useState({});

  const [rooms, setRooms] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setResultMsg({});
    fetchInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const addBooking = (formData) => {
    if (formData && !_.isEmpty(rooms)) {
      setResultMsg({ status: 'One moment...' });
      AppAPI.bengineapi.post(`${property.property_slug}/booking/create/`, {
        booking_room_data: rooms,
        guest: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone: (formData.code + formData.phone),
          address_1: formData.address_1,
          address_2: formData.address_2,
          state: formData.state,
          country: formData.country ? formData.country : '',
          pin_code: formData.pin_code,
        },
        recaptcha: formData.recaptcha
      })
        .then(res => {
          if (res.hash) {
            setResultMsg({ success: 'Success' });
            setTimeout(() => {
              if (props.onSuccess) props.onSuccess(res);
            }, 500);
          } else {
            setResultMsg({ error: 'Booking Failed.' });
          }
        })
        .catch(err => setResultMsg({ error: AppAPI.handleError(err) }));
    }
  };

  const fetchInitData = () => {
    const roomsData = [];
    let total = parseFloat('0.0');
    if (!_.isEmpty(cartData)) {
      /**
       * Cart data contains room type data and quantity of rooms
       * ####  Refer RoomAvailability.js addToCart   ####
       */
      // Init functions has to create a new entry for no of rooms required and pre-fill each room with
      // corresponding base quantity
      cartData.forEach((data) => {
        for (let i = 0; i < data.quantity; i++) {
          let roomData = {
            no_of_guest: data.occupancy_data.base_occupancy,
            no_of_infant: 0,
            no_of_children: 0,
            package: data.package_data,
            occupancy_data: data.occupancy_data,
            package_name: data.package_data.name,
            room_type: data.room_type_data.room_type,
            room_type_name: data.room_type_data.name,
            checkin: Moment(checkin).format('YYYY-MM-DD'),
            checkout: Moment(checkout).format('YYYY-MM-DD'),
            room_type_packages: [
              {
                room_type_package: data.package_data.room_type_package,
                start: Moment(data.checkin).format('YYYY-MM-DD'),
                end: Moment(data.checkout).format('YYYY-MM-DD')
              }
            ]
          };
          roomData = AppUtil.calculateRoomTotalsV2(roomData);
          roomsData.push(roomData);
        }
      });
      setRooms(roomsData);
      setTotal(total);
    }
  };

  const updateIndex = (i, j, k) => {
    if (j != null && k) {
      const tempRooms = [...rooms];

      // eslint-disable-next-line
      switch (k) {
      case 'g+':
        tempRooms[j].no_of_guest =
          tempRooms[j].no_of_guest < tempRooms[j].occupancy_data.max_occupancy
            ? tempRooms[j].no_of_guest + 1
            : tempRooms[j].no_of_guest;
        break;
      case 'g-':
        tempRooms[j].no_of_guest = tempRooms[j].no_of_guest > 1 ? tempRooms[j].no_of_guest - 1 : 1;
        break;
      case 'c+':
        if (tempRooms[j].no_of_children < tempRooms[j].occupancy_data.child_occupancy) {
          tempRooms[j].no_of_children = tempRooms[j].no_of_children + 1;
        }
        break;
      case 'c-':
        if (tempRooms[j].no_of_children > 0) {
          tempRooms[j].no_of_children = tempRooms[j].no_of_children - 1;
        }
        break;
      case 'i+':
        if (tempRooms[j].no_of_infant < tempRooms[j].occupancy_data.infant_occupancy) {
          tempRooms[j].no_of_infant = tempRooms[j].no_of_infant + 1;
        }
        break;
      case 'i-':
        if (tempRooms[j].no_of_infant > 0) {
          tempRooms[j].no_of_infant = tempRooms[j].no_of_infant - 1;
        }
        break;
      }

      tempRooms[j] = AppUtil.calculateRoomTotalsV2(tempRooms[j]);

      let total = 0;
      tempRooms.map(data => (total = parseFloat(total) + parseFloat(data.total)));
      setRooms(tempRooms);
      setTotal(total);
    }
  };

  if (!show) return <></>;

  const validation = {
    first_name: Yup.string().min(2, 'Too Short!').required('Required'),
    last_name: Yup.string().min(1, 'Too Short!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    code: Yup.string().required('Required'),
    recaptcha: Yup.string().required('Required'),
    phone: Yup.string().matches(
      AppConfig.phoneRegex, Strings.phoneNumberValidation
    ).min(8, 'to short').max(10, `${Strings.phoneNumberValidation} without appending 0`).required(
      Strings.phoneNumberValidation
    )
  }

  // Address Validation
  if (property.booking_engine_profile_address) {
    validation['address_1'] = Yup.string().required('Required');
    validation['address_2'] = Yup.string().required('Required');
    validation['country'] = Yup.string().required('Required');
    validation['state'] = Yup.string().required('Required');
    validation['pin_code'] = Yup.string().required('Required');
  }
  const formValidation = Yup.object().shape(validation);

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    code: property ? property.phone_number_code : '',
    country: property ? property.property_country_code : '',
    tands: false,
    recaptcha: '',
    address_1: '',
    address_2: '',
    state: '',
    pin_code: '',
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="xl"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className={'d-flex flex-column'}>
            <p className={'mb-0 '}><strong>{property.name} </strong></p>
            <p className={'mb-0 '}><small>Booking date
              : {AppUtil.formatDateTime(checkin, 'datef')} - {AppUtil.formatDateTime(checkout, 'datef')} </small>
            </p>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(data) => addBooking(data)}>
          {({ errors, touched, setFieldValue, handleSubmit, values }) => (
            <React.Fragment>
              <Modal.Body>
                <form>
                  <div className={'row'}>
                    <div className={'col-md-4 col-sm-12 mb-3 d-none d-lg-block'}>
                      <div className={'event-cover mb-3'}>
                        {(property.booking_engine_content && property.booking_engine_content.bengine_cover_image && property.booking_engine_content.bengine_cover_image.cover_sm) &&
                        <img
                          alt={property.name} className="card-img-top"
                          src={property.booking_engine_content.bengine_cover_image.cover_sm}
                        />
                        }
                      </div>
                      <h5>{property.name}</h5>
                      <p className={'small text-muted'}>
                        <FontAwesomeIcon icon={faMapMarker} className={'me-2'}/>
                        {property.full_address}
                      </p>
                      <hr/>
                      <p className={'small'}><strong>Booking Dates</strong></p>
                      <div className={'row'}>
                        <div className={'col d-inline-flex'}>
                          <DateFormatted containerClass={''} date={checkin}/>
                          <div className={'text-center align-self-center'}>
                            <FontAwesomeIcon className={'mx-4'} icon={faArrowRight}/>
                          </div>
                          <DateFormatted containerClass={''} date={checkout}/>
                        </div>
                      </div>
                      <div className={'border rounded p-2 mt-3'}>
                        <p className={'mb-1 green-cl'}><strong>Go Green</strong></p>
                        <p className={'small text-muted'}>{Strings.digitalAdopterPrint}</p>
                      </div>
                    </div>
                    <div className={'col-lg-8 col-sm-12 border-start'}>
                      <h6 className={'mb-0'}>
                        <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'grey-cl me-1'}/> Room Details
                      </h6>
                      <p className={'small text-muted'}>Configure guest(s) in each of your selected room(s).</p>
                      {rooms && rooms.length > 0 &&
                      <div className="list-group">
                        {rooms.map((data, i) => (
                          <div className={'list-group-item'} key={i}>
                            <div className={'row'}>
                              <div className={'col-8 col-md-8 col-lg-9 mb-3'}>
                                <div className={'row'}>
                                  <div className={'col-12 col-lg-3'}>
                                    <p className={'mb-0'}><strong>Room : {(i + 1)}</strong></p>
                                  </div>
                                  <div className={'col-12 col-lg-9'}>
                                    <div className={'row small mb-2'}>
                                      <div className={'col d-inline-flex'}>
                                        <p className={'mb-0 me-5'}>
                                          <span className={'small text-muted'}>Room Type</span> <br/>
                                          <strong>{data.room_type_name}</strong>
                                        </p>
                                        <p className={'mb-0'}>
                                          <span className={'small text-muted'}>Package</span> <br/>
                                          <strong>{data.package_name}</strong>
                                        </p>
                                      </div>
                                    </div>
                                    <p className={'mb-1 small'}>
                                      <span className={'small text-muted'}>Room Guest</span>
                                    </p>
                                    <div className={'d-inline-flex small text-center'}>
                                      <div className={'me-2'}>
                                        <div className="btn-group btn-group-sm">
                                          <button
                                            className="btn  border red-cl" type="button"
                                            onClick={() => updateIndex(0, i, 'g-')}>
                                            <FontAwesomeIcon icon={faMinus}/>
                                          </button>
                                          <button type="button" className="btn border">
                                            {data.no_of_guest}
                                          </button>
                                          <button
                                            className="btn border green-cl" type="button"
                                            onClick={() => updateIndex(2, i, 'g+')}>
                                            <FontAwesomeIcon icon={faPlus}/>
                                          </button>
                                        </div>
                                        <p className={'small mb-0 mt-1 text-muted'}>Adult (
                                          Max {data.occupancy_data.max_occupancy} )</p>
                                      </div>
                                      <div className={'me-2'}>
                                        <div className="btn-group btn-group-sm" role="group">
                                          <button
                                            className="btn  border red-cl" type="button"
                                            onClick={() => updateIndex(0, i, 'c-')}>
                                            <FontAwesomeIcon icon={faMinus}/>
                                          </button>
                                          <button type="button" className="btn border">
                                            {data.no_of_children}
                                          </button>
                                          <button
                                            className="btn border green-cl" type="button"
                                            onClick={() => updateIndex(2, i, 'c+')}>
                                            <FontAwesomeIcon icon={faPlus}/>
                                          </button>
                                        </div>
                                        <p className={'small mb-0 mt-1 text-muted'}>Child (
                                          Max {data.occupancy_data.child_occupancy} )</p>
                                      </div>
                                      <div className={'me-2'}>
                                        <div className="btn-group btn-group-sm" role="group">
                                          <button
                                            type="button" className="btn  border red-cl"
                                            onClick={() => updateIndex(0, i, 'i-')}>
                                            <FontAwesomeIcon icon={faMinus}/>
                                          </button>
                                          <button type="button" className="btn border">
                                            {data.no_of_infant}
                                          </button>
                                          <button
                                            type="button" className="btn border green-cl"
                                            onClick={() => updateIndex(2, i, 'i+')}>
                                            <FontAwesomeIcon icon={faPlus}/>
                                          </button>
                                        </div>
                                        <p className={'small mb-0 mt-1 text-muted'}>Infant (
                                          Max {data.occupancy_data.infant_occupancy} )</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className={'col-4 col-md-4 col-lg-3 text-end'}>
                                <p className={'mb-0'}><strong>{property.currency} {data.total}</strong></p>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className={'list-group-item'}>
                          <div className={'row'}>
                            <div className={'col-5'}>
                              <p className={'mb-0'}><strong>Total</strong></p>
                            </div>
                            <div className={'col-7 text-end'}>
                              <p className={'mb-0'}><strong>{property.currency} {total.toFixed(2)}</strong></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      }

                      <p className={'mb-0 mt-2 text-muted small'}>
                        Inclusive of taxes, breakdown available in next page.
                      </p>

                      <hr/>

                      <div className={'row'}>
                        <div className={'col-sm-12'}>
                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon icon={faUser} size={'sm'} className={'grey-cl me-1'}/> Name
                          </h6>
                          <p className={'small text-muted'}>Name of the primary contact for the booking.</p>
                          <div className="row mb-3 mb-2">
                            <div className="col-6 form-group">
                              <Input
                                required
                                name="first_name"
                                label={'First Name'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <Input
                                required
                                name="last_name"
                                label={'Last Name'}
                                className={'form-control'}
                              />
                            </div>
                          </div>

                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon icon={faFileContract} size={'sm'} className={'grey-cl me-1'}/> Contact
                          </h6>
                          <p className={'small text-muted'}>Where would you like to receive the booking and be contacted
                            if required ?</p>
                          <div className="row mb-3">
                            <div className="col-lg-6 col-md-6 form-group">
                              <Input
                                required
                                name="email"
                                type={'email'}
                                label={'E-mail'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 ">
                              <div className={'row'}>
                                <div className={'col-4 pr-0 form-group'}>
                                  <Select
                                    className={'form-control rounded-right-0'}
                                    name="code"
                                    label={'Country'}
                                    placeholder="Select an Option"
                                    options={SelectList.phoneCountryCode()}
                                  />
                                </div>
                                <div className={'col-8 pl-0 form-group'}>
                                  <Input
                                    required
                                    type={'tel'}
                                    name="phone"
                                    label={'Phone'}
                                    className={'form-control rounded-left-0'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {property.booking_engine_profile_address && (
                            <>
                              <hr/>
                              <h6 className={'mb-0'}>
                                <FontAwesomeIcon icon={faContactCard} size={'sm'} className={'grey-cl me-1'}/> Address
                              </h6>
                              <p className={'small text-muted'}>Billing / Booking address</p>
                              <div className="mb-3">
                                <div className={'row'}>
                                  <div className="col-6 form-group">
                                    <Input
                                      name='address_1'
                                      label={'Address Line 1'}
                                      className={'form-control'}
                                      placeholder={'House No / Name'}
                                    />
                                  </div>
                                  <div className="col-6 form-group">
                                    <Input
                                      name='address_2'
                                      label={'Address Line 2'}
                                      className={'form-control'}
                                      placeholder={'District'}
                                    />
                                  </div>
                                </div>
                                <div className={'row'}>
                                  <div className="col-4 form-group">
                                    {values.country && !_.isEmpty(SelectList.stateList(values.country))
                                      ? (
                                        <Select
                                          className={'form-control rounded-right-0'}
                                          name='state'
                                          label={'State'}
                                          placeholder='Select an Option'
                                          options={SelectList.stateList(values.country)}
                                        />
                                      ) : (
                                        <Input
                                          name='state'
                                          label={'State'}
                                          className={'form-control'}
                                          disabled={!values.country}
                                        />
                                      )}
                                  </div>
                                  <div className="col-4 form-group">
                                    <Select
                                      className={'form-control rounded-right-0'}
                                      name='country'
                                      label={'Country'}
                                      placeholder='Select an Option'
                                      options={SelectList.countryList()}
                                    />
                                  </div>
                                  <div className="col-4 form-group">
                                    <Input
                                      name='pin_code'
                                      label={'Pin Code'}
                                      className={'form-control'}
                                      type={'number'}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr/>
                            </>
                          )}

                          <div className="row mb-3">
                            <div className="col-12 form-group">
                              <Checkbox
                                required
                                className={'me-2'}
                                name="tands"
                                text="Terms And Conditions"
                              />
                              <p className={'small text-muted'}>
                                I agree to {property.name} Terms of Service & Privacy Policy ( listed below ) and
                                Renzo's
                                <a
                                  rel="noopener noreferrer" target={'_blank'}
                                  href={'https://renzo.in/policies/'}>Terms of service & Privacy Policy</a>.
                              </p>
                              {!_.isEmpty(property.global_content) &&
                              <PolicyLinks inLine={true} Links={property.global_content.policy_links}/>
                              }
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-12 form-group">
                              <div className={'form-element input'}>
                                <span className={'label'}>Recaptcha Validation</span>
                                <ReCAPTCHA
                                  sitekey={AppConfig.reCaptchaKey}
                                  onChange={(data) => setFieldValue('recaptcha', data)}
                                  onExpired={() => setFieldValue('recaptcha', '')}
                                />
                                {errors.recaptcha && touched.recaptcha && (
                                  <span className={'error'}>{errors.recaptcha}</span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      {(property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_room_booking_rules)) &&
                      <div>
                        <FAQList
                          title={'Booking Rules'}
                          FAQList={property.booking_engine_content.bengine_room_booking_rules}
                        />
                      </div>
                      }
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className={'row w-100'}>
                  <div className={'col-12 col-md-12 col-lg-8'}>
                    <Alert statusMsg={resultMsg}/>
                  </div>
                  <div className={'col-12 col-md-12 col-lg-4'}>
                    <div className={'d-grid'}>
                      <button onClick={handleSubmit} className={'btn btn-success btn-lg'} type={'submit'}>
                        Place Order <FontAwesomeIcon className={'white-cl me-2'} icon={faArrowRight}/>
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

RoomBooking.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  checkin: PropTypes.object,
  checkout: PropTypes.object,
  cartData: PropTypes.array,
  property: PropTypes.object
};

/* Export ==================================================================== */
export default RoomBooking;
