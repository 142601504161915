import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components
import Collapse from 'react-bootstrap/Collapse';
import Loading from '../../../components/general/Loading';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faInfoCircle,
  faMinus,
  faPlus,
  faPlusCircle,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

/* ComponentConnect ==================================================================== */
const RoomTypePackageListing = (props) => {
  const {data, loading, cartItems} = props;
  const [priceBreakDown, setPriceBreakDown] = useState();
  return (
    <div className="list-group-item bg-white">
      <div className={'row'}>
        <div className={'col-8 col-md-9 col-lg-10'}>
          <h6 className={'mb-2'}>{data.name}</h6>
          <ul className={'small list-inline mb-1'}>
            {data.inclusion_break_fast &&
            <li className="list-inline-item">
              <FontAwesomeIcon
                icon={data.inclusion_break_fast ? faCheckCircle : faTimesCircle}
                className={`me-1 ${data.inclusion_break_fast ? 'green-cl' : 'grey-cl'}`}
              /> Breakfast
            </li>
            }
            {data.inclusion_lunch &&
            <li className="list-inline-item">
              <FontAwesomeIcon
                icon={data.inclusion_lunch ? faCheckCircle : faTimesCircle}
                className={`me-1 ${data.inclusion_lunch ? 'green-cl' : 'grey-cl'}`}
              /> Lunch
            </li>
            }
            {data.inclusion_dinner &&
            <li className="list-inline-item">
              <FontAwesomeIcon
                icon={data.inclusion_dinner ? faCheckCircle : faTimesCircle}
                className={`me-1 ${data.inclusion_dinner ? 'green-cl' : 'grey-cl'}`}
              /> Dinner
            </li>
            }
            {data.cancellation_policy_display && data.cancellation_policy_display !== 'N.A' &&
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faTimes} className={'gray-cl me-1'}/>
              Cancellation : {data.cancellation_policy_display}
            </li>
            }
          </ul>
          {data.description && <p className={'small mb-1 text-muted'}>{data.description}</p>}
        </div>
        <div className={'col-4 col-md-3 col-lg-2 text-end align-items-center'}>
          {loading
            ? <Loading message={null} size={'sm'} heightMatch={false}/>
            : <React.Fragment>
              <p className={'mb-0 small'}>
                <strong>{data.currency} {data.base_price_effective_total ? data.base_price_effective_total : data.base_price_effective}</strong>
              </p>
              <p className={'mb-0 small text-muted'}>
                {data.no_of_nights ? ` ${data.no_of_nights} night(s)` : 'per night'}
                {data.pricing_data_set &&
                <FontAwesomeIcon
                  onClick={() => setPriceBreakDown(priceBreakDown === data.room_type_package ? false : data.room_type_package)}
                  icon={faInfoCircle}
                  className={'green-cl ml-2'}
                />
                }
              </p>
              {data.base_price_effective_total &&
              <div className={'mt-2'}>
                {cartItems.reduce((total, product) => total + product.quantity, 0) > 0
                  ? <div className="btn-group btn-group-sm" role="group">
                    <button
                      onClick={() => (props.removeFromCart ? props.removeFromCart(data) : null)}
                      type="button" className="btn border red-cl">
                      <FontAwesomeIcon icon={faMinus}/>
                    </button>
                    <button type="button" className="btn border disabled">
                      {cartItems.reduce((total, product) => total + product.quantity, 0)}
                    </button>
                    <button
                      onClick={() => (props.addToCart ? props.addToCart(data) : null)} type="button"
                      className="btn border green-cl">
                      <FontAwesomeIcon icon={faPlus}/>
                    </button>
                  </div>
                  : <button
                    onClick={() => (props.addToCart ? props.addToCart(data) : null)} type="button"
                    className="btn btn-sm btn-block btn-success">
                    <FontAwesomeIcon className={'me-1'} icon={faPlusCircle} size={'sm'}/> Add
                  </button>
                }
              </div>
              }
            </React.Fragment>
          }
        </div>
        <div className={'col-12'}>
          {(!_.isEmpty(data.pricing_data_set)) &&
          <Collapse in={priceBreakDown === data.room_type_package}>
            <div>
              <div className={'row'}>
                <div className={'col-12 col-md-12'}>
                  <hr/>
                  <p className={'small mb-1'}><strong>Price Chart</strong></p>
                  <table className={'table table-sm small table-striped text-center'}>
                    <thead>
                      <tr>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Night(s)</th>
                        <th scope="col">Price/Night</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.pricing_data_set.map((packagePriceDateData, k) => (
                        <tr key={k}>
                          <td>{packagePriceDateData.start_date}</td>
                          <td>{packagePriceDateData.end_date}</td>
                          <td>{packagePriceDateData.no_of_nights}</td>
                          <td>{packagePriceDateData.base_price_effective}</td>
                          <td>{packagePriceDateData.section_price_effective}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Collapse>
          }
        </div>
      </div>
    </div>
  );
};


RoomTypePackageListing.propTypes = {
  loading: PropTypes.bool,
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  removeFromCart: PropTypes.func,
  data: PropTypes.object.isRequired,
};

/* Export ==================================================================== */
export default RoomTypePackageListing;
