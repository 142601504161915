import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
// Const & Libs
import AppUtil from '../../../lib/util';
import WebUtil from '../../../lib/webUtils';
import { Constants } from '../../../constants';
// Components
import { Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import PlaceHolderImage from '../../../images/resource/place-holder-bg.svg';

/* ComponentConnect ==================================================================== */
const EventListView = ({ data }) => {
  const toLink = `/event/${data.event_url}`;
  const navigate = useNavigate();
  return (
    <Card>
      <Link to={toLink}>
        {(!_.isEmpty(data.event_cover_image) && data.event_cover_image.cover_sm)
          ? <Card.Img onClick={() => navigate(toLink)} className={'a bg-dark'} variant="top" src={data.event_cover_image.cover_sm}/>
          : <Card.Img onClick={() => navigate(toLink)} className={'a bg-dark'} variant="top" src={PlaceHolderImage}/>
        }
      </Link>
      <Card.Body>
        <p className={'text-muted small mb-1'}>
          <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl me-2'}/>
          {AppUtil.formatDateTime(data.event_start_date, 'date')}
          {data.event_type === Constants.EVENT_TYPE.RECURRING && `- ${AppUtil.formatDateTime(data.event_end_date, 'date')}`}
        </p>
        <Link className={'no-a-link'} to={toLink}><Card.Title>{data.name}</Card.Title></Link>
        <ul className="list-inline mb-1">
          <li className="list-inline-item small">
            <span className={'badge ' + (WebUtil.bookingStatusColor(data.event_status))}>
              {data.event_status_display}
            </span>
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};

EventListView.propTypes = {
  data: PropTypes.object.isRequired,
  action: PropTypes.func
};

/* Export ==================================================================== */
export default EventListView;
