/**
 * Environment Warning
 */
import React, { useState } from 'react';

import { Alert } from 'react-bootstrap';

import { AppConfig } from '../../constants';

/* Component ==================================================================== */
const EnvironmentWarning = () => {
  const [show, setShow] = useState(AppConfig.environment !== 'production');

  return (
    <React.Fragment>
      {show &&
      <Alert className={'sticky-top mb-0'} variant="warning" onClose={() => setShow(false)} dismissible>
        <strong>Warning: </strong> This is a staging site, connected to end point : {AppConfig.endpoint}.
      </Alert>
      }
    </React.Fragment>
  );
};

/* Export Component ==================================================================== */
export default EnvironmentWarning;
