import React from 'react';
import PropTypes from 'prop-types';
// Constants & libs
import AppUtil from '../../lib/util';

/* ComponentConnect ==================================================================== */
const DateFormatted = (props) => {
  const { date, containerClass, dte, month, year } = props;
  return (
    <div className={`p-2 px-3 grey-light-bg rounded text-center date-formatted ${containerClass}`}>
      <div className="text-uppercase">
        {dte && <span className="small">{AppUtil.formatDateTime(date, 'month')}</span>}
        {month &&
        <React.Fragment>
          <br/><strong className="text-lg">{AppUtil.formatDateTime(date, 'dte')}</strong>
        </React.Fragment>
        }
        {year &&
        <React.Fragment>
          <br/><strong className="small">{AppUtil.formatDateTime(date, 'year')}</strong>
        </React.Fragment>
        }
      </div>
    </div>
  );
};

DateFormatted.propTypes = {
  date: PropTypes.any,
  dte: PropTypes.bool,
  year: PropTypes.bool,
  month: PropTypes.bool,
  containerClass: PropTypes.string
};

DateFormatted.defaultProps = {
  dte: true,
  month: true,
  year: false
};

/* Export ==================================================================== */
export default DateFormatted;
