/**
 * Property Reducer
 *
 */

import _ from 'lodash';

// Set initial state
const initialState = {
  property: null,
  pageContent: null
};

const reducer = (state = initialState, action) => {
  const actionData = (!_.isEmpty(action.data)) ? action.data : {};
  switch (action.type) {
  case 'SET_PROPERTY':
    return {
      ...state,
      property: actionData
    };
  case 'SET_PAGE':
    return {
      ...state,
      pageContent: actionData
    };
  default:
    return state;
  }
};

export default reducer;
