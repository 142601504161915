import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { createRef, useEffect, useState } from 'react';
// Consts and Libs
import AppUtil from '../../lib/util';
import { AppConfig } from '../../constants';
// Components
import Calendar from 'react-calendar';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import 'react-calendar/dist/Calendar.css';

/* ComponentConnect ==================================================================== */
const AvailabilityLookupBox = (props) => {

  const { header, selectedCheckinDate, selectedCheckoutDate, singleLine } = props;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [lookupDisabled, setLookupDisabled] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [checkin, setCheckin] = useState(selectedCheckinDate || Moment().startOf('day'));
  const [checkout, setCheckout] = useState(selectedCheckoutDate || Moment().add(1, 'days'));

  const recaptchaRef = createRef();

  useEffect(()=>{
    setCheckin(selectedCheckinDate || checkin);
    setCheckout(selectedCheckoutDate || checkout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckinDate, selectedCheckoutDate])

  const onSelect = (date) => {
    if (!_.isEmpty(date)) {
      setCheckin(Moment(date[0]));
      setCheckout(Moment(date[1]));
      setShowDatePicker(false);
      setLookupDisabled(false);
    }
  };

  const successCallback = async () => {
    const captcha = await recaptchaRef.current.executeAsync();
    if (props.action && !lookupDisabled) {
      setLookupDisabled(true);
      setShowDatePicker(false);
      props.action(checkin, checkout, captcha);
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className={'lookup-box'}>
      {header &&
      <React.Fragment>
        <h5 className={'mb-1'}>Availability</h5>
        <p className={'text-muted small'}>When would you like to stay ?</p>
      </React.Fragment>
      }

      <div className={'border p-3 rounded'}>
        <div className={'row'}>
          <div className={`${singleLine ? 'col-sm-12 col-md-8' : 'col-12'}`}>
            <div className={'row'}>
              <div className={'col-6'}>
                <div className={'a pb-2 border-bottom'} onClick={() => setShowDatePicker(!showDatePicker)}>
                  <p className={'small text-muted mb-0'}>
                    <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Check-in
                  </p>
                  <p className={'mb-0'}>{AppUtil.formatDateTime(checkin, 'datef')}</p>
                </div>
              </div>
              <div className={'col-6'}>
                <div className={'a pb-2 border-bottom'} onClick={() => setShowDatePicker(!showDatePicker)}>
                  <p className={'small text-muted mb-0'}>
                    <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Check-out
                  </p>
                  <p className={'mb-0'}>{AppUtil.formatDateTime(checkout, 'datef')}</p>
                </div>
              </div>
            </div>
            <div className={`${showDatePicker ? 'collapse.show' : 'collapse'} position-absolute`} id="datePicker">
              <div className="bg-white">
                <div className="text-left">
                  {showDatePicker &&
                    <Calendar
                      selectRange={true}
                      onChange={onSelect}
                      minDate={new Date()}
                      className={'mx-auto'}
                      defaultValue={[checkin.toDate(), checkout.toDate()]}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={`${singleLine ? 'col-sm-12 col-md-4' : 'col-12'} mt-2 d-grid `}>
            <button
              className={'btn btn-primary'}
              disabled={lookupDisabled || !recaptchaLoaded}
              onClick={() => successCallback()}
            >{recaptchaLoaded ? 'Get Availability' : 'Please Wait'}</button>
          </div>
        </div>
      </div>
      <ReCAPTCHA
        sitekey={AppConfig.reCaptchaKeyInvisible}
        ref={recaptchaRef}
        isolated={'true'}
        size={'invisible'}
        asyncScriptOnLoad={() => setRecaptchaLoaded(true)}
      />
    </div>
  );
};

AvailabilityLookupBox.propTypes = {
  action: PropTypes.func,
  selectedCheckinDate: PropTypes.object,
  selectedCheckoutDate: PropTypes.object,
  singleLine: PropTypes.bool,
  header: PropTypes.bool
};

AvailabilityLookupBox.defaultProps = {
  singleLine: false,
  header: true
};

/* Export ==================================================================== */
export default AvailabilityLookupBox;
