import _ from 'lodash';
import { AppConfig } from '../constants';
import { Navigate } from 'react-router-dom';
import React, { createContext, useContext } from 'react';


const HttpContext = createContext({ statusCode: 200 });

export function HttpProvider ({ children, context }) {
  return <HttpContext.Provider value={context}>{children}</HttpContext.Provider>;
}

export function HttpStatus ({ code, children }) {
  const context = useContext(HttpContext);
  if (context) context.statusCode = code;
  return <>{children}</>;
}

export function HttpContextData () {
  return useContext(HttpContext);
}

export function SSRNavigate ({ to, replace = false, code = 301 }) {
  const context = useContext(HttpContext);
  if (context) {
    context.statusCode = code;
    context.to = to;
  }
  return <Navigate to={to} replace={replace}/>;
}

export const getPropertySlug = (httpContext) => {
  let propertyData = '';
  if (!_.isEmpty(httpContext)) {
    if (!_.isEmpty(httpContext.subdomains)) {
      propertyData = httpContext.subdomains[0];
    } else if (!_.isEmpty(httpContext.hostName) && !AppConfig.homeDomains.includes(httpContext.hostName)) {
      propertyData = httpContext.hostName;
    }
  }

  if (AppConfig.DEV && AppConfig.customSlug) {
    propertyData = AppConfig.customSlug;
  }

  return propertyData;
};
