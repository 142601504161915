const UTIL = {
  /**
   * Returns platform
   */
  isSSR: () => {
    return !(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    );
  },
  bookingStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'bg-info';
    case 2:
      return 'bg-warning';
    case 3:
      return 'bg-success';
    case 4:
      return 'bg-dark';
    default:
      return 'bg-secondary';
    }
  },
  availabilityStatusColor: status => {
    if (status > 75) {
      return 'green-cl';
    } else if (status > 50) {
      return 'blue-cl';
    } else if (status > 25) {
      return 'purple-dark-cl';
    } else if (status > 10) {
      return 'orange-cl';
    } else {
      return 'red-cl';
    }
  },
  paymentStatusColor: (status) => {
    switch (status) {
    case -1:
    case 0:
      return 'bg-danger';
    case 1:
      return 'bg-success';
    case 2:
      return 'bg-warning';
    case 3:
      return 'bg-primary';
    case 4:
      return 'bg-info';
    case 5:
      return 'bg-info';
    default:
      return 'bg-secondary';
    }
  },
  fetchStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'bg-info';
    case 1:
      return 'bg-success';
    case 2:
      return 'bg-warning';
    case 3:
      return 'bg-primary';
    case 4:
      return 'bg-secondary';
    default:
      return 'bg-danger';
    }
  },
  disabledDaysFunction: (date, days) => {
    // The following function does as follows:
    // Convert the days into as - Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 0
    // Then those that are not present are put to the disabledDays array
    // And finally the disabledDays array is checked to see if the date.getDay() is present in it
    days = days.split(',');
    days = days.map((day) => {
      if (day === 'Mon') return 1;
      if (day === 'Tue') return 2;
      if (day === 'Wed') return 3;
      if (day === 'Thu') return 4;
      if (day === 'Fri') return 5;
      if (day === 'Sat') return 6;
      return 0;
    });

    const disabledDays = [];

    for (let i=0; i<=6; i++) {
      if (days.includes(i)) {
        continue;
      } else {
        disabledDays.push(i);
      }
    }

    return disabledDays.includes(date.getDay());
  }

};

/* Export ==================================================================== */
export default UTIL;
