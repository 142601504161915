/**
 * Loading
 *
 */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// Const & Libs
import WEBUtil from '../../lib/webUtils';
// Components
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */
const Loading = (props) => {
  const { heightMatch, size, message } = props;
  const [height, setHeight] = useState(250);

  useEffect(() => {
    if (!WEBUtil.isSSR()) {
      setHeight(document.body.scrollHeight * 0.7);
    }
  }, []);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ height: (height && heightMatch ? `${height}px` : 'auto') }}
    >
      <div className="align-self-center text-center">
        <FontAwesomeIcon icon={faSpinner} size={size} spin={true}/>
        {message && <p className="mt-2 small text-muted">{message}</p>}
      </div>
    </div>
  );
};

Loading.defaultProps = {
  message: 'Loading',
  size: '2x',
  heightMatch: true
};

Loading.propTypes = {
  size: PropTypes.string,
  message: PropTypes.string,
  heightMatch: PropTypes.bool
};

/* Export Component ==================================================================== */
export default Loading;
