import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

/* ComponentConnect ==================================================================== */
const Alert = ({statusMsg}) => {
  const { success, status, error, info } = statusMsg;
  const processMessage = (message) => {
    if (typeof message === 'object') {
      return (<ul className={'mb-0'}>{message.map((messageItem, i) => <li key={i}>{messageItem}</li>)}</ul>);
    } else {
      return (<p className={'mb-0'}>{message}</p>);
    }
  };

  return (
    <div>
      {!_.isEmpty(success) &&
      <div className={'alert alert-success'}>
        {processMessage(success)}
      </div>
      }

      {!_.isEmpty(status) &&
      <div className={'alert alert-info'}>
        {processMessage(status)}
      </div>
      }

      {!_.isEmpty(error) &&
      <div className={'alert alert-danger'}>
        {processMessage(error)}
      </div>
      }

      {!_.isEmpty(info) &&
      <div className={'alert alert-warning'}>
        {processMessage(info)}
      </div>
      }
    </div>
  );
};

Alert.propTypes = {
  statusMsg: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

Alert.defaultProps = {
  statusMsg: {}
};

/* Export ==================================================================== */
export default Alert;
