import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// Constants & Libs
import AppUtil from '../../lib/util';
import WEBUtil from '../../lib/webUtils';
import {Constants, Strings} from '../../constants';
// Components
import Alert from '../../components/general/Alert';
import Loading from '../../components/general/Loading';
import Property404 from '../property/components/Property404';
import OrderListView from '../order/components/OrderListView';
import ClickToView from '../../components/general/ClickToView';
import LocationDetailsBox from '../../components/booking-engine/LocationDetailsBox';
import SupportInformation from '../../components/booking-engine/supportInformation';

import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import PlaceHolderImage from '../../images/resource/place-holder-bg.svg';

// Actions
import * as BookingActions from '../../redux/booking/actions';
import * as EventActions from '../../redux/event/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
  ticketBooking: state.booking.ticketBooking
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setTicketBooking: BookingActions.setTicketBooking,
  setEvent: EventActions.setEvent,
};

/* Component ==================================================================== */
const TicketBookingDetails = (props) => {
  const { property, event, ticketBooking, setTicketBooking, setEvent } = props;
  const [loading, setLoading] = useState(_.isEmpty(ticketBooking));
  const [refreshing, setRefreshing] = useState(false)

  const { ticketID } = useParams();

  useEffect(() => {
    setLoading(true);
    setTicketBooking(ticketID, property.property_slug)
      .then((res) => {
        if (res.event) {
          setEvent(res.event.event_url, property.property_slug)
            .then(()=>setLoading(false))
        }
      })
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketID]);

  const fetchInitData = () => {
    setTicketBooking(ticketID, property.property_slug)
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  }

  if (loading) return <Loading/>;
  if (_.isEmpty(ticketBooking) && (WEBUtil.isSSR() || !loading)) return <Property404 property={property}/>;

  const sideBar = (
    <div>
      <div className={'mb-3'}>
        <LocationDetailsBox property={property} event={event}/>
      </div>
      <div className={'mb-3'}>
        <SupportInformation property={property}/>
      </div>
    </div>
  );

  return (
    <div className={'screen-container'}>
      <Helmet>
        <title>Booking Confirmation {ticketBooking.ref_no} : {property.name}</title>
      </Helmet>
      <div
        className={'booking-header'}
        style={event.event_cover_image && event.event_cover_image.cover_2x && {
          backgroundImage: `url(${event.event_cover_image.cover_2x})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <table className={'booking-header-inner w-100'}>
          <tbody>
            <tr>
              <td className={'text-light'}>
                <div className={'container'}>
                  <h1>Booking Confirmation</h1>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={'container'}>
        <div className={'row mt-4'}>
          <div className={'col-lg-8 sm-12 mb-sm-5'}>
            {ticketBooking.ticket_booking_status === Constants.TICKET_BOOKING_STATUS.PROVISIONAL && (
              <div className={'mb-3'}>
                <Alert
                  statusMsg={{
                    info: `${Strings.eventTicketBookingConfirmationText} Expires at ${AppUtil.formatDateTime(ticketBooking.ticket_booking_time_out)}.`
                  }}
                />
              </div>
            )}

            <p><strong>Event Information</strong></p>
            <div className={'row mb-3'}>
              <div className={'col-12 col-md-5'}>
                <div className={'event-cover'}>
                  {(event.event_cover_image && event.event_cover_image.cover_sm)
                    ? <img className="card-img-top" src={event.event_cover_image.cover_sm} alt={event.name} />
                    : <img alt={'Empty Cover'} className="card-img-top bg-dark" src={PlaceHolderImage} />
                  }
                </div>
              </div>
              <div className={'col-12 col-md-7'}>
                <p className={'small mb-0 text-muted'}>Event Name</p>
                <h4 className={'mb-3'}>{event.name}</h4>
                <ul className={'list-inline mb-2'}>
                  <li className={'list-inline-item'}>
                    <p className={'small mb-0 text-muted'}>Quantity</p>
                    <h4>{ticketBooking.quantity}</h4>
                  </li>
                  {ticketBooking.ticket &&
                  <React.Fragment>
                    <li className={'list-inline-item mx-2'}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </li>
                    <li className={'list-inline-item'}>
                      <p className={'small mb-0 text-muted'}>Ticket</p>
                      <h4>{ticketBooking.ticket.name}</h4>
                    </li>
                  </React.Fragment>
                  }
                </ul>
                <ul className={'list-inline list-inline-formatted'}>
                  <li className={'list-inline-item'}>
                    <p className={'small mb-0 text-muted'}>Date</p>
                    <p className={'mb-0'}><strong>{AppUtil.formatDateTime(ticketBooking.date, 'datef')}</strong></p>
                  </li>
                  {ticketBooking.ticket_booking_id &&
                  <li className={'list-inline-item'}>
                    <p className={'small text-muted mb-0'}>Ticket Booking ID</p>
                    <p className={'mb-0'}><strong>{ticketBooking.ticket_booking_id}</strong></p>
                  </li>
                  }
                </ul>
              </div>
            </div>
            <hr/>
            <div className={'row'}>
              <div className={'col'}>
                <ul className={'list-inline list-inline-formatted'}>
                  <li className={'list-inline-item'}>
                    <p className={'small text-muted mb-0'}>Ticket Booking Reference</p>
                    <p>{ticketBooking.ref_no}</p>
                  </li>
                  {ticketBooking.ticket_booking_id &&
                  <li className={'list-inline-item'}>
                    <p className={'small text-muted mb-0'}>Ticket Booking ID</p>
                    <p>{ticketBooking.ticket_booking_id}</p>
                  </li>
                  }
                  <li className={'list-inline-item'}>
                    <p className={'small text-muted mb-0'}>Booking Status</p>
                    <p>
                      <span className={'badge ' + (WEBUtil.bookingStatusColor(ticketBooking.ticket_booking_status))}>
                        {ticketBooking.ticket_booking_status_display}
                      </span>
                    </p>
                  </li>
                  <li className={'list-inline-item'}>
                    <p className={'small text-muted mb-0'}>Booking Time</p>
                    <p>{AppUtil.formatDateTime(ticketBooking.created)}</p>
                  </li>
                </ul>
                {ticketBooking.guest &&
                <div className={'mb-3'}>
                  <p className={'mb-1'}><strong>Primary Guest</strong></p>
                  <ul className={'list-inline list-inline-formatted'}>
                    {ticketBooking.guest.name &&
                    <li className={'list-inline-item'}>
                      <p className={'small text-muted mb-0'}>Name</p>
                      <p>{ticketBooking.guest.name}</p>
                    </li>
                    }

                    {ticketBooking.guest.phone &&
                    <li className={'list-inline-item'}>
                      <p className={'small text-muted mb-0'}>Phone</p>
                      <p>{ticketBooking.guest.phone}</p>
                    </li>
                    }

                    {ticketBooking.guest.email &&
                    <li className={'list-inline-item'}>
                      <p className={'small text-muted mb-0'}>Email</p>
                      <p><ClickToView content={ticketBooking.guest.email} hiddenText={'Click to See Email'}/></p>
                    </li>
                    }
                  </ul>
                </div>
                }
              </div>
            </div>
            {!_.isEmpty(ticketBooking.orders) &&
            <div className={'mb-3'}>
              <p><strong>Order(s)</strong></p>
              <ul className={'list-unstyled'}>
                {ticketBooking.orders.map((order, i) => (
                  <li key={i}>
                    <OrderListView refreshing={refreshing} refresh={fetchInitData} order={order}/>
                  </li>
                ))}
              </ul>
            </div>
            }
          </div>
          <div className={'col-md-4 col-sm-12 mb-5'}>
            {sideBar}
          </div>
        </div>
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(TicketBookingDetails);

/* Export ==================================================================== */
const TicketBookingDetailsPage = {
  element: <Element/>
};

export default TicketBookingDetailsPage;
