import _ from 'lodash';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import React from 'react';
// Const & Libs
import { getPropertySlug } from '../lib/http';
// Sub Containers
import BookingEngineLaunch from './components/BookingEngineLaunch';
import PropertyNotAllowed from './property/components/PropertyNotAllowed';

// Components
import Header from '../components/ui/header';
import Footer from '../components/ui/footer';
// Actions
import * as PropertyActions from '../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setProperty: PropertyActions.setProperty
};

/* SSR Initiation Loading ======================================================= */
const loadData = (store, httpContext) => {
  const propertySlug = getPropertySlug(httpContext);
  if (propertySlug) return store.dispatch(PropertyActions.setProperty(propertySlug));
};

const Launch = (props) => {
  const { property } = props;
  if (!_.isEmpty(property)) {
    return (
      <div>
        <Header/>
        {(!property.website_ok && !property.booking_engine_ok)
          ? <PropertyNotAllowed property={property}/>
          : <Outlet/>
        }
        <Footer/>
      </div>
    );
  } else {
    return <BookingEngineLaunch/>;
  }
};

/* Export ==================================================================== */
const Element = connect(mapStateToProps, mapDispatchToProps)(Launch);
const LaunchComponent = {
  loadData,
  element: <Element/>
};
export default LaunchComponent;
