import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// Constants & Libs
import { Strings } from '../../../constants';
import { HttpStatus } from '../../../lib/http';

/* ComponentConnect ==================================================================== */
const PropertyNotAllowed = ({ property }) => {
  const { booking_engine_content } = property;

  return (
    <div>
      <HttpStatus code={404}/>
      <Helmet>
        <title>{`${property.name} : Booking Engine`}</title>
      </Helmet>
      <div
        className={'home-header'}
        style={booking_engine_content.bengine_cover_image && booking_engine_content.bengine_cover_image.cover_2x && {
          backgroundImage: `url(${booking_engine_content.bengine_cover_image.cover_2x})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <table className={'home-header-inner w-100 h-100'}>
          <tbody>
            <tr>
              <td className={'text-center text-light'}>
                <h1 className={'mb-4'}>{property.name || 'Hello There'}</h1>
                <p>{Strings.bookingEngineDisabled}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

PropertyNotAllowed.propTypes = {
  property: PropTypes.object,
  booking_engine_content: PropTypes.object
};

/* Export ==================================================================== */
export default PropertyNotAllowed;
