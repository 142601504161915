import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// Constants & Libs
import { HttpStatus } from '../../../lib/http';

/* ComponentConnect ==================================================================== */
const Property404 = ({ property }) => {
  const { booking_engine_content } = property;

  return (
    <div>
      <HttpStatus code={404}/>
      <Helmet>
        <title>{`Not Found : ${property.name}`}</title>
      </Helmet>
      <div
        className={'home-header'}
        style={booking_engine_content.bengine_cover_image && booking_engine_content.bengine_cover_image.cover_2x && {
          backgroundImage: `url(${booking_engine_content.bengine_cover_image.cover_2x})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <table className={'home-header-inner w-100 h-100'}>
          <tbody>
            <tr>
              <td className={'text-center text-light'}>
                <h1 className={'mb-4'}>404 Not Found!</h1>
                <h6>We could not find what you were looking for.</h6>
                <Link to={'/'} className={'mt-5 btn btn-success'}>
                  Back Home
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Property404.propTypes = {
  property: PropTypes.object,
  booking_engine_content: PropTypes.object
};

/* Export ==================================================================== */
export default Property404;
