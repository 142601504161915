import property from './property/reducer';
import general from './general/reducer';
import booking from './booking/reducer';
import event from './event/reducer';
import room from './room/reducer';


const appReducer = {
  booking,
  event,
  general,
  property,
  room
};

export default appReducer;
