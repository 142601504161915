/**
 * Click to show content.
 *  used to hide sensitive info from bots and crawlers.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Component ==================================================================== */
const ClickToView = (props) => {

  const { content, buttonClass, hiddenText } = props;

  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      {show ? content : <span onClick={() => setShow(true)} className={`${buttonClass} a`}>{hiddenText}</span>}
    </React.Fragment>
  );
};

ClickToView.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hiddenText: PropTypes.string,
  buttonClass: PropTypes.string
};

ClickToView.defaultProps = {
  hiddenText: 'Click to Show',
  buttonClass: 'btn btn-link p-0 m-0 btn-sm'
};

/* Export Component ==================================================================== */
export default ClickToView;
