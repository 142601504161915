/**
 * PageHeader
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
const PageHeader = (props) => {
  const { title, description, back, subHeader, backLink, icon } = props;
  const navigate = useNavigate();
  return (
    <div className={'page-header-wrapper'}>
      <div className="page-header">
        <div className={subHeader ? 'h5' : 'h1'}>
          {(back !== false && navigate) &&
          <div
            role="presentation" onClick={() => navigate(-1)}
            className={'btn btn-outline-dark me-2 ' + (subHeader ? 'btn-sm' : 'mb-2 ')}>
            <FontAwesomeIcon icon={faArrowLeft} size={subHeader ? 'sm' : 'lg'}/></div>
          }

          {backLink &&
          <Link to={backLink} className={'btn btn-outline-dark me-2 ' + (subHeader ? 'btn-sm' : 'mb-2 ')}>
            <FontAwesomeIcon icon={faArrowLeft} size={subHeader ? 'sm' : 'lg'}/>
          </Link>
          }

          {icon && icon}
          {title}
        </div>
        {description && <p className={subHeader ? 'mb-0 small' : ''}>{description}</p>}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  back: PropTypes.bool,
  title: PropTypes.string,
  subHeader: PropTypes.bool,
  history: PropTypes.object,
  backLink: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.object
};

/* Export Component ==================================================================== */
export default PageHeader;
