/**
 * Room / Room Type Reducer
 *
 */

import _ from 'lodash';

// Set initial state
const initialState = {
  roomType: null,
};

const reducer = (state = initialState, action) => {
  const actionData = (!_.isEmpty(action.data)) ? action.data : {};
  switch (action.type) {
  case 'SET_ROOM_TYPE':
    return {
      ...state,
      roomType: actionData
    };
  default:
    return state;
  }
};

export default reducer;
