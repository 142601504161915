import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// Consts & Libs
import { HttpStatus } from '../lib/http';
// Components
import Property404 from './property/components/Property404';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* ComponentConnect ==================================================================== */
const NotFound = ({ property }) => {
  if (!_.isEmpty(property)) return <Property404 property={property}/>;
  return (
    <div className="index screen-container site-wrapper site-wrapper-absolute">
      <HttpStatus code={404}/>
      <Helmet>
        <title>Its a 404!</title>
        <meta name="description" content="The content you are looking for is not found."/>
      </Helmet>

      <div className="site-wrapper-inner">
        <div className="cover-container row">
          <div className={'p-3 mt-3 mb-0 text-center mx-auto'}>
            <div className={'col-md-12 p-lg-2'}>
              <h1><FontAwesomeIcon className={'red-cl'} icon={faTimesCircle} size={'sm'}/> 404 Error !</h1>
              <p className={'lead'}>Hmm, that page doesn't exist.</p>
              <p>You are seeing this page because the URL you requested does not exist.</p>
              <p>Please <a href={'https://renzo.in/contact'}>report to us</a> if you think this is an error.</p>
              <a className={'btn btn-success'} href={'https://renzo.in'}>Back to Renzo</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(NotFound);

/* Export ==================================================================== */
const NotFoundPage = {
  element: <Element/>
};

export default NotFoundPage;

