import AppAPI from '../../lib/api';


export const setRoomType = (roomData, propertyId) => (dispatch) => new Promise(async (resolve, reject) => {
  return AppAPI.bengineapi.get(`${propertyId}/room-type/${roomData}/`)
    .then((res) => {
      dispatch({
        type: 'SET_ROOM_TYPE',
        data: res
      });
      return resolve(res);
    })
    .catch(err => {
      dispatch({
        type: 'SET_ROOM_TYPE',
        data: {}
      });
      return reject(err);
    });
});

