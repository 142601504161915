import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// Components
import Property404 from './components/Property404';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
const PropertyNotFound = (props) => {
  const { property } = props;
  const { booking_engine_content } = property;

  if (_.isEmpty(property) || _.isEmpty(booking_engine_content)) return <></>;

  return (
    <Property404 property={property} />
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(PropertyNotFound);

/* Export ==================================================================== */
const PropertyNotFoundPage = {
  element: <Element/>
};

export default PropertyNotFoundPage;
