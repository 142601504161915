/**
 * General Actions
 *
 */

/**
 * Set SSR Data from load data to be used in component
 */
export const setSSRData = (data) => (dispatch) => new Promise(async (resolve) => {
  dispatch({
    type: 'SET_SSR_DATA',
    data: data
  });
  return resolve(data);
});
/**
 * Remove SSR Data
 */
export const unSetSSRData = () => (dispatch) => new Promise(async (resolve) => {
  dispatch({
    type: 'SET_SSR_DATA',
    data: null
  });
  return resolve();
});

/**
 * Set SSR Data from load data to be used in component
 */
export const setCaptcha = (data) => (dispatch) => new Promise(async (resolve) => {
  dispatch({
    type: 'SET_CAPTCHA',
    data: data
  });
  return resolve(data);
});

/**
 * Remove SSR Data
 */
export const unSetCaptcha = () => (dispatch) => new Promise(async (resolve) => {
  dispatch({
    type: 'SET_CAPTCHA',
    data: null
  });
  return resolve();
});
