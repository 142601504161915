import App from './App';

import NotFound from './containers/NotFound';

import LaunchComponent from './containers/Launch';

// Property Pages
import PropertyHome from './containers/property/PropertyHome';
import PropertyNotFoundPage from './containers/property/PropertyNotFound';
import PropertyPageDetails from './containers/property/PropertyPageDetails';
import PropertyAmenitiesPage from './containers/property/PropertyAmenities';
import PropertyImageGalleryPage from './containers/property/PropertyImageGallery';
// Room
import RoomTypeListPage from './containers/room/RoomTypeList';
import RoomAvailabilityPage from './containers/room/RoomAvailability';
import RoomTypeDetailsPage from './containers/room/RoomTypeDetails';
// Event
import EventListPage from './containers/event/EventList';
import EventDetailsPage from './containers/event/EventDetails';
// Bookings
import BookingDetailsPage from './containers/booking/BookingDetails';
import TicketBookingDetailsPage from './containers/booking/TicketBookingDetails';


const routes = [
  {
    ...App,
    children: [
      {
        ...LaunchComponent,
        path: '/',
        children: [
          {
            ...PropertyHome,
            index: true
          },
          {
            ...PropertyAmenitiesPage,
            path: '/amenities'
          },
          {
            ...PropertyImageGalleryPage,
            path: '/gallery'
          },
          {
            ...PropertyPageDetails,
            path: '/p/:pageID'
          },
          {
            ...PropertyNotFoundPage,
            path: '/error'
          },
          {
            ...RoomTypeListPage,
            path: '/room'
          },
          {
            ...RoomAvailabilityPage,
            path: '/room/availability'
          },
          {
            ...RoomAvailabilityPage,
            path: '/room/availability/:checkin/to/:checkout'
          },
          {
            ...RoomTypeDetailsPage,
            path: '/room/:roomTypeID'
          },
          {
            ...EventListPage,
            path: '/event'
          },
          {
            ...EventDetailsPage,
            path: '/event/:eventID'
          },
          {
            ...TicketBookingDetailsPage,
            path: 'booking/e/:ticketID'
          },
          {
            ...BookingDetailsPage,
            path: 'booking/r/:bookingID'
          }
        ]
      },
      {
        ...NotFound,
        path: '*'
      }
    ]
  }
];

export default routes;
