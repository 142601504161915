import React from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import WEBUtil from '../../../lib/webUtils';
import { Constants } from '../../../constants';
// Components
import Loading from '../../../components/general/Loading';

/* ComponentConnect ==================================================================== */
const EventTicketListing = ({ tickets, emptyMsg, loading, action }) => {
  return (
    <div className="table-responsive">
      <table className="table align-middle border">
        <thead>
          <tr>
            <th width={'50%'}>Ticket</th>
            <th className={''}>Quantity</th>
            <th className={'text-right'}>Price</th>
            <th className={'text-right'}/>
          </tr>
        </thead>
        <tbody>
          {tickets && tickets.length > 0 ?
            <React.Fragment>
              {tickets.map((data, i) => (
                <tr key={i}>
                  <td>
                    <h6 className={'mb-2'}>{data.name}</h6>
                    {data.description &&
                  <p className={'mb-0 text-muted small display-linebreak'}>{data.description}</p>
                    }
                  </td>
                  <td className={''}>
                    {data.ticket_quantity}
                  </td>
                  <td className={'text-right'}>
                    <p className={'mb-0'}><strong>{data.currency} {data.base_price_effective}</strong></p>
                    <p className={'mb-0 small text-muted'}>Per Unit</p>
                  </td>
                  <td className={'text-center'}>
                    {loading ?
                      <Loading message={null} size={'sm'} heightMatch={false}/>
                      :
                      <React.Fragment>
                        {data.availability &&
                      <React.Fragment>
                        <button
                          disabled={data.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED && parseInt(data.availability.available_tickets) === 0}
                          onClick={() => action(data)}
                          type="button" className="btn btn-success btn-sm">
                          {data.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED && parseInt(data.availability.available_tickets) === 0 ? 'Sold Out' : 'Book'}
                        </button>
                        <p
                          className={`mt-1 mb-0 small ${WEBUtil.availabilityStatusColor(data.availability.availability_percentage)}`}>
                          {(data.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED) && `${data.availability.available_tickets} : `} Available
                        </p>
                      </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  </td>
                </tr>
              ))}
            </React.Fragment>
            :
            <tr>
              <td colSpan={'4'} align={'center'}>
                {emptyMsg ? <p>{emptyMsg}</p> : <p>No Tickets listed for this event.</p>}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

EventTicketListing.propTypes = {
  tickets: PropTypes.array.isRequired,
  action: PropTypes.func.isRequired,
  emptyMsg: PropTypes.string,
  loading: PropTypes.bool
};
/* Export ==================================================================== */
export default EventTicketListing;
