import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Nav, Navbar } from 'react-bootstrap';
// Const & Libs
import AppUtil from '../../lib/util';

/* Component ==================================================================== */
const Header = () => {

  const property = useSelector(state => state.property.property);
  if (property && _.isEmpty(property.booking_engine_content)) return <></>;

  return (
    <div className="header border-bottom">
      <div>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <Link className="navbar-brand" to={'/'}>
                {(property.logo && property.logo.logo) ?
                  <img width={240} src={property.logo.logo} alt={property.name}/>
                  :
                  <h4>{property.name}</h4>
                }
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle/>
            {!_.isEmpty(property.booking_engine_content.bengine_header_links) &&
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {property.booking_engine_content.bengine_header_links.map((data, i) =>
                  <React.Fragment key={i}>
                    {AppUtil.isUrl(data.link)
                      ? <a className={'nav-link'} href={data.link}>{data.title}</a>
                      : <Link to={`/${data.link}`} className={'nav-link'}>{data.title}</Link>
                    }
                  </React.Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
            }
            <Navbar.Collapse className="justify-content-end">
              {property.allow_room_booking_engine &&
              <Link to={'/room/availability/'} className="btn btn-primary btn-sm">
                Book Now
              </Link>
              }
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

/* Export ==================================================================== */
export default Header;
