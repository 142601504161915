import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Constants & Libs
import WebUtil from '../../lib/webUtils';
import { Constants } from '../../constants';
import { getPropertySlug } from '../../lib/http';
// Components
import Property404 from './components/Property404';
import Loading from '../../components/general/Loading';
import LocationDetailsBox from '../../components/booking-engine/LocationDetailsBox';
import SupportInformation from '../../components/booking-engine/supportInformation';
// Actions
import * as PropertyActions from '../../redux/property/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pageContent: state.property.pageContent
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPage: PropertyActions.setPage
};

/* SSR Initiation Loading ======================================================= */
const loadData = (store, httpContext, { pageID }) => {
  return store.dispatch(PropertyActions.setPage(pageID, getPropertySlug(httpContext)));
};

/* Component ==================================================================== */
const PropertyPageDetails = (props) => {
  const { property, pageContent, setPage } = props;
  const [loading, setLoading] = useState(pageContent === null && !WebUtil.isSSR());
  const { pageID } = useParams();

  useEffect(() => {
    setLoading(true);
    setPage(pageID, property.property_slug)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageID]);

  if (loading) return <Loading/>;
  if (_.isEmpty(pageContent) && (WebUtil.isSSR() || !loading)) return <Property404 property={property}/>;

  const alignContent = (pageContent, content) => {
    let className;
    switch (pageContent.layout_type) {
    case Constants.PAGE_LAYOUT.SIDEBAR:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0';
      break;
    case Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0 ';
      break;
    case Constants.PAGE_LAYOUT.NO_SIDEBAR_CENTER_ALIGNED:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0  mx-auto';
      break;
    case Constants.PAGE_LAYOUT.NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0 mx-auto';
      break;
    case Constants.PAGE_LAYOUT.FULL_WIDTH:
      className = 'col-lg-12 sm-12 mb-sm-5 mb-md-0';
      break;
    case Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE:
      className = 'col-lg-12 sm-12 mb-sm-5 mb-md-0';
      break;
    default:
      className = 'col-lg-12 sm-12 mb-sm-5 mb-md-0';
    }
    return (
      <div className={className}>
        {content}
      </div>
    );
  };

  const sideBar = (
    <React.Fragment>
      <div className={'mb-3'}>
        <LocationDetailsBox property={property}/>
      </div>
      <div className={'mb-3'}>
        <SupportInformation property={property}/>
      </div>
    </React.Fragment>
  );

  const content = (
    <React.Fragment>
      {(pageContent.layout_type === Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED) ?
        <React.Fragment>
          <p>
            {pageContent.short_content && pageContent.short_content}
          </p>
        </React.Fragment>
        :
        <React.Fragment>
          <h2>{pageContent.title}</h2>
          <hr/>
          <p className={'display-linebreak font-weight-light mb-3'}>
            {pageContent.short_content && pageContent.short_content}
          </p>
          {pageContent.image && pageContent.image.cover &&
          <img className="d-block w-75 mx-auto" src={pageContent.image.cover} alt={pageContent.title}/>
          }
        </React.Fragment>
      }
      <p className={'display-linebreak font-weight-light mb-3'}>{pageContent.content}</p>
    </React.Fragment>
  );

  const coverImageLayouts = [Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE, Constants.PAGE_LAYOUT.NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED, Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE];
  const coverImageSidebarLayouts = [Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE, Constants.PAGE_LAYOUT.SIDEBAR];

  return (
    <div className={'screen-container'}>
      <Helmet>
        <title>{pageContent.title} | {property.name}</title>
        <meta name="description" content={pageContent.short_content || `${pageContent.title}. ${property.name}`}/>
      </Helmet>

      {coverImageLayouts.includes(pageContent.layout_type) &&
      <div
        className={'property-page-header'}
        style={(pageContent.image && pageContent.image.cover_2x) && {
          backgroundImage: `url(${pageContent.image.cover_2x})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <table className={'property-page-header-inner w-100'}>
          <tbody>
            <tr>
              <td className={'text-light'}>
                <div className={'container'}>
                  {alignContent(pageContent, <h1>{pageContent.title}</h1>)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      }

      <div className={'container'}>
        <div className={'row mt-4'}>
          {alignContent(pageContent, content)}
          {coverImageSidebarLayouts.includes(pageContent.layout_type) &&
          <div className={'col-md-4 col-sm-12 mb-5 d-none d-lg-block'}>{sideBar}</div>
          }
        </div>
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(PropertyPageDetails);

/* Export ==================================================================== */
const PropertyPageDetailsPage = {
  loadData,
  element: <Element/>
};

export default PropertyPageDetailsPage;
