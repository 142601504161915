/**
 * Footer Links
 * format and prints footer links
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Const & Libs
import AppUtil from '../../lib/util';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
const PolicyLinks = (props) => {
  const { Links, inLine } = props;

  if (_.isEmpty(Links)) return <></>;

  return (
    <ul className={`${inLine ? 'list-inline' : 'list-unstyled'} mb-1`}>
      {Links.map((data, i) => (
        <li className={`mb-1 ${inLine && 'list-inline-item'}`} key={i}>
          <FontAwesomeIcon
            icon={AppUtil.isUrl(data.link) ? faExternalLinkAlt : faDotCircle}
            className={'me-2 text-muted'} size={'xs'}
          />
          {AppUtil.isUrl(data.link)
            ? <a className={'small text-muted'} href={data.link}>{data.title}</a>
            : <Link to={`/p/${data.link}`} className={'small text-muted'}>{data.title}</Link>
          }
        </li>
      ))}
    </ul>
  );

};

PolicyLinks.defaultProps = {
  inLine: false
};

PolicyLinks.propTypes = {
  Links: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  inLine: PropTypes.bool
};

/* Export Component ==================================================================== */
export default PolicyLinks;
