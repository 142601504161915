import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// Consts & Libs
import WEBUtils from '../../../lib/webUtils'
// Components
import RoomTypePackageListing from './RoomTypePackageListing';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBaby,
  faBed,
  faChild,
  faDoorOpen,
  faInfoCircle,
  faRulerCombined,
  faSmoking,
  faSmokingBan,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {Accordion} from 'react-bootstrap';

import PlaceHolderImage from '../../../images/resource/place-holder-bg.svg';


/* ComponentConnect ==================================================================== */
const RoomAvailabilityListing = (props) => {
  const {data, loading, cartItems} = props;
  const selected = cartItems.reduce((total, product) => total + product.quantity, 0);
  const [showDescription, setShowDescription] = useState(0);


  return (
    <div>
      <div className={'mb-3 p-2 border rounded'}>
        <div className={'row'}>
          <div className={'col-4'}>
            <div className={'mb-2'}>
              {(data.room_type_cover_image && data.room_type_cover_image.cover_sm)
                ? <img alt={data.name} className="w-100" src={data.room_type_cover_image.cover_sm}/>
                : <img alt={'Empty Cover'} className={'bg-dark w-100'}  src={PlaceHolderImage} />
              }
            </div>
          </div>
          <div className={'col-8'}>
            <p className={'float-md-right text-right mb-0 badge badge-success'}>
              {selected > 0 && `Selected : ${selected}`}
            </p>
            <h5 className={'my-2'}>{data.name}</h5>
            <ul className="list-inline inline-list-spacing small mb-0">
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faUser} size={'sm'} className={'me-2 blue-cl'}/>
                {data.base_occupancy}

              </li>
              {data.child_occupancy !== 0 &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faChild} size={'sm'} className={'me-2 blue-cl'}/>
                {data.child_occupancy}
              </li>
              }
              {data.infant_occupancy !== 0 &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faBaby} className={'me-2 blue-cl'}/>
                {data.infant_occupancy}
              </li>
              }

              {data.room_dimension &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faRulerCombined} className={'me-2 blue-cl'}/>
                {data.room_dimension}
              </li>
              }

              {data.smoking_type_display &&
              <li className="list-inline-item">
                <FontAwesomeIcon
                  icon={data.smoking_type_display.includes('Non') ? faSmokingBan : faSmoking}
                  className={'me-2 blue-cl'}/> {data.smoking_type_display}
              </li>
              }

              {data.bed_type_display &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faBed} className={'me-2 blue-cl'}/> {data.bed_type_display}
              </li>
              }

              {data.view_type_display &&
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faDoorOpen} className={'me-2 blue-cl'}/> {data.view_type_display}
              </li>
              }
            </ul>
            {data.short_description &&
            <p
              className={'mb-0 a small'} onClick={() => setShowDescription(showDescription === 1 ? 0 : 1)}>
              <u><FontAwesomeIcon icon={faInfoCircle} className={'gray-cl me-1'}/> More Information</u>
            </p>
            }
            {data.availability &&
            <p
              className={`mt-1 mb-0 small ${WEBUtils.availabilityStatusColor(data.availability.availability_percentage)}`}>
              {data.availability.available_rooms} rooms
              available {data.availability.available_rooms > data.max_quantity && `( Max ${data.max_quantity} )`}
            </p>
            }
          </div>
        </div>

        <Accordion activeKey={showDescription}>
          <Accordion.Collapse eventKey={1}>
            <div className={'mt-2'}>
              {data.short_description && <p className={'small'}>{data.short_description}</p>}
            </div>
          </Accordion.Collapse>
        </Accordion>
        {!_.isEmpty(data.packages) &&
        <div className={'mt-2'}>
          <h6>Packages</h6>
          <div className="list-group list-group-sm">
            {data.packages.map((data, i) => (
              <RoomTypePackageListing
                loading={loading}
                data={data} key={i}
                addToCart={props.addToCart}
                removeFromCart={props.removeFromCart}
                cartItems={cartItems.filter(item => (item.room_type_package === data.room_type_package))}
              />
            ))}
          </div>
        </div>
        }
      </div>
    </div>
  );
};

RoomAvailabilityListing.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  action: PropTypes.func,
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  removeFromCart: PropTypes.func,
};

/* Export ==================================================================== */
export default RoomAvailabilityListing;
