import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { createRef, useEffect, useState } from 'react';
// Consts and Libs
import AppUtil from '../../lib/util';
import { AppConfig, Constants } from '../../constants';
// Components
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

/* ComponentConnect ==================================================================== */
const AvailabilityLookupBoxEvent = ({ action, selectedDate, event, disabledSelection, disabledDates }) => {

  const [date, setDate] = useState(
    event.event_type === Constants.EVENT_TYPE.SINGLE
      ? Moment(event.event_start_date)
      : (selectedDate ? Moment(selectedDate) : Moment().startOf('day'))
  );

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [lookupDisabled, setLookupDisabled] = useState(!event.event_status === Constants.EVENT_STATUS.COMPLETED);

  let maxDate, minDate = Moment().startOf('day'), selectDate = date;

  const recaptchaRef = createRef();
  useEffect(() => setLookupDisabled(disabledSelection), [disabledSelection]);

  const onSelect = (date) => {
    setDate(Moment(date));
    setShowDatePicker(false);
    setLookupDisabled(false);
  };

  const successCallback = async () => {
    const captcha = await recaptchaRef.current.executeAsync();
    if (action && !lookupDisabled) {
      setLookupDisabled(true);
      setShowDatePicker(false);
      action(date, captcha);
      recaptchaRef.current.reset();
    }
  };

  if (event) {
    if (Moment(event.event_start_date) > Moment().startOf('day')) {
      minDate = Moment(event.event_start_date);
    }
    maxDate = Moment(event.event_end_date);
  }

  useEffect(()=>{
    if (event.event_type === Constants.EVENT_TYPE.SINGLE) {
      setDate(Moment(event.event_start_date));
    } else {
      if (Moment(event.event_start_date) > Moment().startOf('day')) {
        setDate(Moment(event.event_start_date));
      } else {
        let currentDate = Moment().startOf('day')
        while (currentDate < Moment(event.event_end_date).add(1,'day')){
          if (!disabledDates(currentDate.toDate())) {
            setDate(currentDate);
            break;
          }
          currentDate = currentDate.add(1, 'day')
        }
      }
    }
  }, [event, disabledDates])

  if (_.isEmpty(event)) return <></>;

  return (
    <div className={'ticket-box'}>
      <div className={'border p-3 rounded'}>
        <div className={'row'}>
          <div className={'col-6'}>
            {event.event_type === Constants.EVENT_TYPE.RECURRING && event.event_status !== Constants.EVENT_STATUS.COMPLETED ?
              <React.Fragment>
                <div className={'a'} onClick={() => setShowDatePicker(!showDatePicker)}>
                  <p className={'small text-muted mb-0'}>
                    <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Date
                  </p>
                  <p className={'mb-0'}>{AppUtil.formatDateTime(selectDate, 'datef')}</p>
                </div>
                <div className={(showDatePicker ? 'collapse.show' : 'collapse') + ' position-absolute'} id="datePicker">
                  <div className="bg-white">
                    <div className="text-left">
                      {showDatePicker &&
                      <Calendar
                        selectRange={false}
                        onChange={onSelect}
                        className={'mx-auto'}
                        minDate={minDate.toDate()}
                        value={selectDate.toDate()}
                        maxDate={maxDate ? maxDate.toDate() : null}
                        tileDisabled={({date}) => disabledDates ? disabledDates(date) : null}
                      />
                      }
                    </div>
                  </div>
                </div>
              </React.Fragment>
              :
              <React.Fragment>
                <div>
                  <p className={'small text-muted mb-0'}>
                    <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Date
                  </p>
                  <p className={'mb-0'}>{AppUtil.formatDateTime(selectDate, 'datef')}</p>
                </div>
              </React.Fragment>
            }
          </div>
          <div className={'col-6 d-grid'}>
            <button
              className={'btn btn-primary'}
              disabled={lookupDisabled || !recaptchaLoaded}
              onClick={() => successCallback()}
            >{recaptchaLoaded ? 'Get Availability' : 'Please Wait'}</button>
          </div>
        </div>
      </div>
      <ReCAPTCHA
        sitekey={AppConfig.reCaptchaKeyInvisible}
        ref={recaptchaRef}
        isolated={'true'}
        size={'invisible'}
        asyncScriptOnLoad={() => setRecaptchaLoaded(true)}
      />
    </div>
  );
};

AvailabilityLookupBoxEvent.propTypes = {
  action: PropTypes.func,
  selectedDate: PropTypes.object,
  disabledSelection: PropTypes.bool,
  event: PropTypes.object.isRequired
};

/* Export ==================================================================== */
export default AvailabilityLookupBoxEvent;
