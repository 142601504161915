import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
// Components
import { Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaby, faChild, faShapes, faUser } from '@fortawesome/free-solid-svg-icons';

import PlaceHolderImage from '../../../images/resource/place-holder-bg.svg';

/* ComponentConnect ==================================================================== */
const RoomTypeListView = ({ data }) => {
  const toLink = `/room/${data.room_type_url}`;
  const navigate = useNavigate();
  return (
    <Card>
      <Link to={toLink}>
        {(!_.isEmpty(data.room_type_cover_image) && data.room_type_cover_image.cover_sm)
          ? <Card.Img onClick={() => navigate(toLink)} className={'a bg-dark'} variant="top" src={data.room_type_cover_image.cover_sm}/>
          : <Card.Img onClick={() => navigate(toLink)} className={'a bg-dark'} variant="top" src={PlaceHolderImage}/>
        }
      </Link>
      <Card.Body>
        <Link className={'no-a-link'} to={toLink}><Card.Title>{data.name}</Card.Title></Link>
        <ul className="list-inline mb-1">
          <li className="list-inline-item small">
            <FontAwesomeIcon icon={faUser} size={'sm'} className={'me-2 blue-cl'}/>
            {data.base_occupancy}
          </li>
          {data.child_occupancy !== 0 &&
          <li className="list-inline-item small">
            <FontAwesomeIcon icon={faChild} size={'sm'} className={'me-2 blue-cl'}/>
            {data.child_occupancy}
          </li>
          }
          {data.infant_occupancy !== 0 &&
          <li className="list-inline-item small">
            <FontAwesomeIcon icon={faBaby} className={'me-2 blue-cl'}/>
            {data.infant_occupancy}
          </li>
          }
          {data.room_shape_display &&
          <li className="list-inline-item small">
            <FontAwesomeIcon icon={faShapes} size={'sm'} className={'me-2 blue-cl'}/>
            {data.room_shape_display}
          </li>
          }
        </ul>
      </Card.Body>

    </Card>
  );
};

RoomTypeListView.propTypes = {
  data: PropTypes.object.isRequired,
  action: PropTypes.func
};

/* Export ==================================================================== */
export default RoomTypeListView;
