import React from 'react';
import { Provider } from 'react-redux';
import { hydrateRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { config } from '@fortawesome/fontawesome-svg-core';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import Routes from './Routes';
import reducers from './redux';

// Redux store for client side
const store = configureStore({
  reducer: reducers,
  preloadedState: window.INITIAL_STATE
});

// Fontawesome config for SSR
config.autoAddCss = false;

// Sentry tracing
if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    release: process.env.REACT_APP_VERSION,
    integrations: [new BrowserTracing()],

    tracesSampleRate: 0.01,

    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null;

      // Suppressing hydration errors
      const oriExpress  = String(hint.originalException)
      if (oriExpress.includes('Hydration') || oriExpress.includes('rendered') || oriExpress.includes('rendering')) return null;

      return event;
    }

    // beforeSend(event) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     const options = {
    //       eventId: event.event_id,
    //       title: Strings.errorReportingTitle,
    //       subtitle: Strings.errorReportingSubTitle,
    //       subtitle2: Strings.errorReportingSubTitle2,
    //       successMessage: Strings.errorReportingSuccess,
    //     };
    //     if (!_.isEmpty(event.user)) {
    //       options.user = {
    //         email: event.user.email,
    //         name: event.user.name,
    //       };
    //     }
    //     Sentry.showReportDialog(options);
    //   }
    //   return event;
    // },
  });
}

const container = document.getElementById('root');
const RouteList = () => useRoutes(Routes);
hydrateRoot(
  container,
  <Provider store={store}>
    <BrowserRouter>
      <div><RouteList/></div>
    </BrowserRouter>
  </Provider>
);

