import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import React from 'react';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import { Strings } from '../../constants';
// Components
import ListView from '../../components/general/ListView';
import RoomTypeListView from './components/RoomTypeListView';
import PageHeader from '../../components/booking-engine/PageHeader';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
const RoomTypeList = (props) => {
  const { property } = props;
  const { booking_engine_content } = property;

  const fetchData = (page, callback) => {
    AppAPI.bengineapi.get(`${property.property_slug}/room-type/?${page && `page=${page}`}`)
      .then((res) => {
        const params = { nextParam: res.next ? AppUtil.getURLParam(res.next, 'page') : false };
        res.results.length > 0 ? callback(res.results, params) : callback();
      })
      .catch((err) => {
        callback();
        AppAPI.handleError(err);
      });
  };

  const emptyView = () => {
    return (
      <div className={'col-12 text-center mb-5'}>
        <div className={'w-50 mx-auto mb-5'}>
          <img alt={'Empty Room List'} className="w-100" src={require('../../images/resource/cycling-bg.png')}/>
        </div>
        <p>{Strings.roomTypeEmptyText}</p>
      </div>
    );
  };

  const renderRow = (data, key) => {
    return (
      <div className={'col-sm-6 col-md-4 col-lg-3 mb-3'} key={key}>
        <RoomTypeListView data={data}/>
      </div>
    );
  };

  return (
    <div className={'screen-container'}>
      <Helmet>
        <title>{`Room Types : ${property.name}`}</title>
        <meta name="description" content={`All room types in ${property.name}. ${booking_engine_content.bengine_short_description}`}/>

      </Helmet>

      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <PageHeader
              icon={<FontAwesomeIcon icon={faHome} className={'me-2 grey-cl'}/>}
              title={'Room(s)'}
              back={false}
            />
            <div className={'row'}>
              <ListView
                rowView={renderRow}
                onFetch={fetchData}
                firstLoader={true}
                emptyView={emptyView}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Element = connect(mapStateToProps, mapDispatchToProps)(RoomTypeList);

/* Export ==================================================================== */
const RoomTypeListPage = {
  element: <Element/>
};

export default RoomTypeListPage;
