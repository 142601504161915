import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faEllipsisH } from '@fortawesome/free-solid-svg-icons';


const ListView = (props) => {
  const [tube, setTube] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [paginationParam, setPaginationParam] = useState(null);
  const [paginationStatus, setPaginationStatus] = useState('fetching');

  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.firstLoader) {
      props.onFetch(paginationParam, _updateRows, props.searchParams);
    } else {
      setPaginationStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    setTube([...new Set([...tube, ...data])]);
    setLoading(false);
  }, [data, isLoading, tube]);

  const _updateRows = (rows = [], options = {}) => {
    if (!_.isEmpty(rows)) {
      setLoading(true);
      setData(rows);
      setPaginationStatus(options.nextParam ? 'waiting' : 'allLoaded');
      setPaginationParam(options.nextParam);
    } else {
      setData([]);
      setPaginationStatus('allLoaded');
    }
  };

  const _onPaginate = () => {
    if (paginationStatus === 'allLoaded') return null;
    setPaginationStatus('fetching');
    props.onFetch(paginationParam, _postPaginate, props.searchParams);
  };

  const _postPaginate = (rows = [], options = {}) => {
    _updateRows(rows, options);
  };

  const _renderData = () => {
    if (tube) {
      return (
        Object.keys(tube).map(function (keyName, keyIndex) {
          return (props.rowView(tube[keyName], keyIndex));
        })
      );
    }
  };

  const _renderPaginationView = () => {
    if (props.pagination) {
      if ((paginationStatus === 'fetching') || (paginationStatus === 'firstLoad' && props.firstLoader === true)) {
        return paginationFetchingView();
      } else if (paginationStatus === 'waiting' && !_.isEmpty(data)) {
        return paginationWaitingView();
      } else if (paginationStatus === 'allLoaded' && !_.isEmpty(data)) {
        return paginationAllLoadedView();
      }
    }
    if (_.isEmpty(data) && paginationStatus === 'allLoaded') {
      return paginationEmptyView();
    }
    return <></>;
  };

  const _renderEnd = (object) => {
    if (props.colSpan) {
      return <tr>
        <td className={'my-5 text-center'} colSpan={props.colSpan}>{object}</td>
      </tr>;
    }

    return <div className={'col-12 my-5 text-center'}> {object}</div>;
  };

  const paginationFetchingView = () => {
    if (props.loadingView) {
      return props.loadingView();
    }
    return _renderEnd(<Loading heightMatch={false}/>);
  };

  const paginationAllLoadedView = () => {
    if (paginationStatus === 'allLoaded') {
      if (props.endListView) {
        return props.endListView();
      }

      return _renderEnd(<FontAwesomeIcon icon={faEllipsisH} size={'2x'}/>);
    }
  };

  const paginationWaitingView = () => {

    if (!props.pagination) {
      return null;
    }

    if (props.loadMoreView) {
      return props.loadMoreView();
    }

    return _renderEnd(
      <button className="btn btn-outline" onClick={() => _onPaginate()}>
        <FontAwesomeIcon icon={faArrowDown} className={'me-2'}/>
        Load More
      </button>
    );
  };

  const paginationEmptyView = () => {
    if (props.emptyView) return props.emptyView();
    if (props.searchParams) {
      return _renderEnd(<p className={'text-secondary text-center'}>End of Search.</p>);
    }
    return _renderEnd(<p className={'text-secondary text-center'}>Empty.</p>);
  };

  return (
    <React.Fragment>
      {_renderData()}
      {_renderPaginationView()}
    </React.Fragment>
  );
};

ListView.propTypes = {
  rowView: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  pagination: PropTypes.bool,
  firstLoader: PropTypes.bool,
  colSpan: PropTypes.number,

  refresh: PropTypes.bool,
  searchParams: PropTypes.string,
  emptyView: PropTypes.func,
  loadingView: PropTypes.func,
  endListView: PropTypes.func,
  loadMoreView: PropTypes.func,

  element: PropTypes.string,
  class: PropTypes.string
};

export default ListView;
